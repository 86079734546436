<template>
  <div class="app__inner">
    <!-- Mobile Navigation -->
    <AppOffCanvas class="hide-md" />

    <!-- Header -->
    <AppHeader class="hide-sm d-block--md"></AppHeader>

    <!-- Navigation -->
    <AppNavigation />

    <!-- Breadcrumb -->
    <AppBreadcrumb />

    <div class="app-content container">
      <!-- Main content goes here -->
      <main class="main-content">
        <router-view class="main-content-inner" />
      </main>
    </div>

    <!-- Footer -->
    <AppFooter />

    <WSCLIENT :init="initWsClient"></WSCLIENT>
  </div>
</template>

<script>
import AppBreadcrumb from '@/components/App/AppBreadcrumb';
import AppFooter from '@/components/App/AppFooter';
import AppHeader from '@/components/App/AppHeader';
import AppNavigation from '@/components/App/AppNavigation';
import AppOffCanvas from '@/components/App/AppOffCanvas';
import WSCLIENT from '@/components/WSCLIENT';

import mixins from '@/shared/mixins/index';
import * as Sentry from '@sentry/browser';

export default {
  name: 'DefaultLayout',

  mixins: [mixins],

  components: {
    AppBreadcrumb,
    AppFooter,
    AppHeader,
    AppNavigation,
    AppOffCanvas,
    WSCLIENT
  },

  data() {
    return {
      defaultNode: '',
      initWsClient: false
    };
  },

  computed: {
    offcanvasVisible() {
      return this.$store.state.offcanvas.visible;
    },

    user() {
      return this.$store.state.user;
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.$store.dispatch('GET_USER').then(() => {
        this.initWsClient = true;

        // Error tracking
        Sentry.setUser({
          email: this.user.userlogin
        });

        Sentry.setTag({
          country: this.user.outcountry,
          doclanguage: this.user.outdoclanq,
          menuelanguage: this.user.outmenuelanq
        });
      });
    }
  }
};
</script>

<style lang="scss">
.app__inner {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  background-color: #ffffff;

  &-inner {
    padding: 1.5rem 1rem 0;
    position: relative;

    @media screen and (min-width: $screen-lg) {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
</style>
