<!--
	To connect to the signalR-Server we need to wait until user informations
	like verification and servername has been requested.
	If so we are informed via the props value. We watch for changes on call the connect method.
-->
<template>
  <span></span>
</template>

<script>
/* eslint-disable no-undef */
import * as signalR from '@microsoft/signalr/dist/esm/index';
import 'msgpack5/dist/msgpack5.js';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack/dist/esm/index';

export default {
  name: 'WSCLIENT',

  props: {
    init: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    hostUrl() {
      let result = null;
      if (this.baseUrl) {
        result = `${this.baseUrl}/hubs/webnotification`; // 'https://m-iplan-test-notification.meiko.de/hubs/webnotification'
      }
      return result;
    }
  },

  data() {
    return {
      application: 'WEB',
      connection: null,
      environment: null,
      baseUrl: null,
      retryMillis: [0, 2000, 10000, 30000],
      token: null
    };
  },

  methods: {
    setup() {
      if (!this.token || !this.hostUrl) {
        return false;
      }

      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(this.hostUrl, { accessTokenFactory: () => this.token })
        .withHubProtocol(new MessagePackHubProtocol())
        .configureLogging(signalR.LogLevel.Information)
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: retryContext => {
            console.log('nextRetryDelayInMilliseconds', retryContext);
            if (retryContext.previousRetryCount < this.retryMillis.length) {
              return this.retryMillis[retryContext.previousRetryCount];
            } else {
              return this.retryMillis[this.retryMillis.length - 1];
            }
            //After each failed retry attempt, previousRetryCount will be incremented by one,
            // elapsedMilliseconds will be updated to reflect the amount of time spent reconnecting so far in milliseconds

            // retryContext.previousRetryCount
            // retryContext.elapsedMilliseconds
            // retryContext.retryReason containing the error message
            // return 'null' for stop reconnecting
          }
        })
        .build();

      // Register events and start connection if connection has been setup
      if (this.connection) {
        this.registerEvents();
        this.start();
      }
    },

    async start() {
      try {
        await this.connection.start();
        this.connection.invoke('Subscribe', { environment: this.environment, application: this.application });
        console.log('SignalR Connected.');
      } catch (error) {
        this.$message({
          message: 'Could not connect to Downloadserver',
          type: 'error',
          showClose: true,
          duration: 8000
        });
        throw new Error('Could not connect to Server');
      }
    },

    registerEvents() {
      /**
       * ##### Onreconnected ######
       */
      this.connection.onreconnected(() => {
        //Subscribe again to the M-iPlan notification server
        this.connection.invoke('Subscribe', { environment: this.environment, application: this.application });

        this.$message({
          message: `Re-connect with Downloadserver`,
          type: 'info',
          showClose: true,
          duration: 8000
        });
      });

      /**
       * ##### Onreconnecting ######
       */
      this.connection.onreconnecting(error => {
        this.$message({
          message: `Try to re-connect with Downloadserver (${error})`,
          type: 'info',
          showClose: true,
          duration: 8000
        });
      });

      /**
       * #### OnClose ####
       */
      this.connection.onclose(() => {
        this.$message({
          message: 'Could not connect to Downloadserver',
          type: 'error',
          showClose: true,
          duration: 8000
        });
      });

      /**
       * ##### Subscribe to custom events ######
       */
      this.connection.on('ChatMessage', message => {
        const { message: msg, title, type } = message;
        const Type = type.toLowerCase() || 'dialog';

        const data = {
          Type,
          Options: {},
          Content: msg,
          Title: title
        };

        this.handleWebAlerts(data);
      });

      this.connection.on('DownloadIsAvailable', pushDto => {
        const { session, sourceServerName, fileName: orgFileName, fileData: data, fileType } = pushDto;
        const blob = new Blob([data], { type: fileType });
        const fileName = `${session}_${sourceServerName}${orgFileName}`;
        this.saveAs(blob, fileName);
      });
    },

    /**
     * Handle web alert events
     * Show information to the user in different manner.
     *
     * @param Object data
     * @returns void
     */
    handleWebAlerts(data) {
      const { Content: message, Title: title, Type } = data;

      const defaultOptions = {
        duration: 6000,
        position: 'top-right',
        type: 'info'
      };
      const type = Type.toLowerCase() || 'dialog';
      const options = Object.assign(defaultOptions, data.Options);

      // Show Dialog
      if (type === 'dialog') {
        const SETTINGS = Object.assign(options, {
          content: message,
          title
        });

        this.$store.commit('dialog/OPEN_DIALOG', SETTINGS);
      }

      //Show Notification
      if (type === 'notification') {
        const SETTINGS = Object.assign(options, {
          message,
          title
        });

        this.$notify(SETTINGS);
      }
    },

    /**
     * Prepare download
     *
     * @param Blob blob
     * @param String name
     */
    saveAs(blob, name) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display:none';

      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = name;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  },

  watch: {
    init() {
      this.baseUrl = this.$localStorage.get('servername1');
      this.application = this.$localStorage.get('APPLICATION');
      this.environment = this.$localStorage.get('ENVIRONMENT');
      this.token = this.$session.get('token');
    },

    token(newValue) {
      if (newValue && this.init) {
        this.setup();
      }
    }
  }
};
</script>
