import Vue from 'vue';
import Vuex from 'vuex';
import actions from '@/store/actions';
import mutations from '@/store/mutations';
import getters from '@/store/getters';
import config from '@/config/index';
import auth from '@/store/modules/auth';
import breadcrumb from '@/store/modules/breadcrumb';
import dialog from '@/store/modules/dialog';
import pages from '@/store/modules/pages';
import machineinfo from '@/store/modules/machineinfo';
import webdownloadjobs from '@/store/modules/webdownloadjobs';
import weblistchooser from '@/store/modules/weblistchooser';
import webmainconfigu from '@/store/modules/webmainconfigu';
import webmiqma from '@/store/modules/webmiqma';
import webmiqmaelem from '@/store/modules/webmiqmaelem';
import webmulti from '@/store/modules/webmulti';
import webnews from '@/store/modules/webnews';
import weboffcanvas from '@/store/modules/weboffcanvas';
import webpoplist from '@/store/modules/webpoplist';
import webpoptilechooser from '@/store/modules/webpoptilechooser';
import webribbon from '@/store/modules/webribbon';
import webroomheatcalc from '@/store/modules/webroomheatcalc';
import websearch from '@/store/modules/websearch';
import websession from '@/store/modules/websession';
import webtilechooser from '@/store/modules/webtilechooser';
import webtree from '@/store/modules/webtree';

import i18n from '@/i18n';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    app: {
      backendversion: '',
      currentView: 'Login',
      debug: {
        visible: false
      },
      documentLoaderPath: config.api.isHttps
        ? 'https://' + config.api.host + config.api.path + '/DocumentSingle?data='
        : 'http://' + config.api.host + config.api.path + '/DocumentSingle?data=',
      reportLoaderPath: config.api.isHttps
        ? 'https://' + config.api.host + config.api.path + '/ReportSingle?data='
        : 'http://' + config.api.host + config.api.path + '/ReportSingle?data=',
      imageLoaderPath: config.api.isHttps
        ? 'https://' + config.api.host + config.api.path + '/ImageSingle?data='
        : 'http://' + config.api.host + config.api.path + '/ImageSingle?data=',
      imageLoaderStyle: config.api.isHttps
        ? 'https://' + config.api.host + config.api.path + '/ImageSingle?'
        : 'http://' + config.api.host + config.api.path + '/ImageSingle?',
      isLoginPage: true,
      language: '',
      loaded: false,
      loadingBackground: 'rgba(255,255,255,0.8)',
      loadingIcon: 'el-icon-loading',
      loadingText: i18n.t('LOADING'),
      loading: false,
      loginViewVisible: true,
      passwordResetViewVisible: false,
      sessionNumber: '',
      svgLoaderPath: config.api.isHttps
        ? 'https://' + config.api.host + config.api.path + '/SvgSingle?'
        : 'http://' + config.api.host + config.api.path + '/SvgSingle?'
    },
    controls: [],
    firstNode: {},
    node: {},
    offcanvas: {
      visible: false
    },
    actionControls: ['WEBPOPTILECHOOSER', 'WEBPOPLIST', 'WEBLISTCHOOSER', 'WEBOFFCANVAS'],
    actionNodes: ['MN000000010', 'MN000000011', 'MN000000724'],
    protectedControls: ['WEBFRONTPAGE', 'WEBRIBBON'],
    secondaryControls: ['WEBMIQMAELEM', 'WEBMIQMAELEMOPT', 'WEBMULTI'],
    user: {
      APPLICATION: null,
      ENVIRONMENT: null,
      HASREADPRIVACYMIP: false,
      SERVERNAME1: null,
      name: '',
      userlogin: '',
      userrole: ''
    },
    visibleControls: []
  },
  mutations,

  actions,

  getters,

  modules: {
    auth,
    breadcrumb,
    dialog,
    machineinfo,
    pages,
    webdownloadjobs,
    weblistchooser,
    webmainconfigu,
    webmiqma,
    webmiqmaelem,
    webmulti,
    webnews,
    weboffcanvas,
    webpoplist,
    webpoptilechooser,
    webribbon,
    webroomheatcalc,
    websearch,
    websession,
    webtilechooser,
    webtree
  }
});
