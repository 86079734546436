// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'es6-promise/auto';
import Vue from 'vue';

// EventBus
import Events from './events/index';

import NodeControls from '@/components/Anzcontrols/NodeControls';

// ELEMENT UI
import ElementUI from 'element-ui';
import enLang from 'element-ui/lib/locale/lang/en';
// import deLang from 'element-ui/lib/locale/lang/de';
import './assets/scss/theme-meiko.scss';

// STYLES
import './assets/scss/styles.scss';

// ROUTER
import App from './App';
import router from './router';

// STORE
import store from './store/index';

// AXIOS
import API from './config/api';
import VueAxios from 'vue-axios';

// i18n
import i18n from './i18n/index';

// PROGRESSBAR
// import VueProgressBar from "vue-progressbar";
// const progressbarOptions = {
//   color: "#3498db",
//   failedColor: "#D80F16",
//   thickness: "5px",
//   transition: {
//     speed: "0.2s",
//     opacity: "0.6s",
//     termination: 300
//   }
// };

// STORAGE
import VueLocalStorage from 'vue-localstorage';
import VueSessionStorage from 'vue-sessionstorage';

// SERVICE WORKER
// import './registerServiceWorker';

// ERROR TRACKING
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  Vue,
  dsn: 'https://2c06be301fde454ab758e8bddb6ab027@o176579.ingest.sentry.io/1261097',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'm-iplan.meiko.de', /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

Sentry.setExtra({
  version: process.env.VUE_APP_VERSION
});

// CONFIG
Vue.config.productionTip = false;

// PROTOTYPES
Vue.prototype.$events = Events;

/**
 * Register global components
 */
Vue.component('NodeControls', NodeControls);

/**
 * USE Block
 */
Vue.use(ElementUI, { locale: enLang });
Vue.use(VueAxios, API);
// Vue.use(VueProgressBar, progressbarOptions);
Vue.use(VueLocalStorage);
Vue.use(VueSessionStorage);

/* eslint-disable no-new */
new Vue({
  el: '#app',

  router,

  store,

  i18n,

  components: { App },

  template: '<App/>'
});
