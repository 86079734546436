<template>
  <div>
    <!-- Header -->
    <AppHeader class="hide-sm d-block--md" :variant="'light'"></AppHeader>

    <main class="app-content app-content--default">
      <!-- Main content goes here -->
      <router-view></router-view>
    </main>

    <!-- Footer -->
    <AppFooter :variant="'default'" />
  </div>
</template>

<script>
import AppFooter from '@/components/App/AppFooter';
import AppHeader from '@/components/App/AppHeader';

import { getLanguage } from '@/services/browser';
import mixins from '@/shared/mixins/index';

export default {
  name: 'DefaultLayout',

  mixins: [mixins],

  components: {
    AppFooter,
    AppHeader
  },

  data() {
    return {
      defaultNode: '',
      language: getLanguage()
    };
  }
};
</script>
