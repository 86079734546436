import Vue from 'vue';

export default {
  INITIAL_NODE(state) {
    if (state.firstNode.WebLoadNode === undefined) {
      return false;
    }

    const nextNodes = state.firstNode.WebLoadNode.split(';');
    nextNodes.pop(); // Remove last ';'
    nextNodes.sort();

    return nextNodes[nextNodes.length - 1];
  },

  IS_AUTHENTICATED() {
    const token = Vue.prototype.$session.get('token');

    // ToDO: Sicherer machen!
    if (token && token.length) {
      return true;
    } else {
      return false;
    }
  },

  IS_IDENTITY_AZURE(state) {
    return state.user.IDENTITYPROVIDER.toLowerCase() === 'azure';
  },

  SESSION_NUMBER(state) {
    return Vue.prototype.$session.get('Sessionnumber') || state.app.sessionNumber;
  }
};
