<template>
  <header class="app-header" :class="'app-header--' + variant">
    <div class="container">
      <el-row class="flex--md" align="bottom">
        <el-col :xs="24" :sm="12" :md="12" class="a-center m-b-half">
          <el-col :xs="4" :sm="5" :md="4">
            <div class="app-header__logo">
              <SVGLogo />
            </div>
          </el-col>
          <el-col :xs="20" :sm="19" :md="20">
            <span class="app-header__headline" v-t="'MIPLAN_PRODUCTCONFIGURATOR'"></span>
          </el-col>
        </el-col>

        <el-col :xs="24" :sm="12" :md="12" class="a-right a-bottom" v-if="variant === ''">
          <div class="hide-sm">
            <div class="a-right">
              <AppNavigationSecondary class="m-b-1" />
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-button v-if="user.userrole === 'ADMIN' && visibleControl === 'WEBMAINCONFIGU'" @click="onClickBtnDebug" class="debug__btn-toggle">
      {{ $t('DEBUG_MODE') }}:
      <span v-if="debug.visible">{{ $t('ON') }}</span>
      <span v-else>{{ $t('OFF') }}</span>
    </el-button>
  </header>
</template>

<script>
import SVGLogo from '@/assets/img/logo-meiko.svg';
import AppNavigationSecondary from './AppNavigationSecondary';

export default {
  name: 'AppHeader',

  components: {
    AppNavigationSecondary,
    SVGLogo
  },

  props: {
    variant: {
      type: String,
      required: false,
      default: ''
    }
  },

  computed: {
    debug() {
      return this.$store.state.app.debug;
    },

    user() {
      return this.$store.state.user;
    },

    visibleControl() {
      return this.$store.state.visibleControls[0];
    }
  },

  methods: {
    onClickBtnDebug() {
      this.$store.commit('SET_APP_DEBUG', {
        visible: !this.debug.visible
      });
    }
  }
};
</script>

<style lang="scss">
.app-header {
  background-image: linear-gradient(to top, #ffffff 0%, #e8edf0 100%);

  &--w-bg {
    background-image: url('../../assets/img/bg-main.png');
    background-repeat: repeat;
  }

  &--light {
    background-image: none;
  }

  @media screen and (max-width: $screen-md) {
    padding-bottom: 0.6rem;
  }

  @media screen and (max-width: $container-width) {
    padding-right: 0.6rem;
    padding-left: 0.6rem;
  }

  @media screen and (min-width: $screen-lg) {
    padding-right: 0;
    padding-left: 0;
  }

  &__headline {
    font-family: $font-headline;
    font-size: 1.4rem;
    hyphens: auto;

    @media screen and (max-width: 550px) {
      font-size: 1rem;
    }
  }

  &__logo {
    margin-right: 1rem;
  }
}
</style>
