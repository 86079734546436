<template>
  <li class="nav__item">
    <!-- Show dropdown -->
    <Dropdown v-if="data.XcontrolChild === 'MNUDROPDOWN'" :data="data"></Dropdown>

    <!-- Normal links with explicit url -->
    <router-link
      v-else-if="!isActionControl"
      :to="'/pages/' + data.Xnodekey"
      :title="data.Lanqkey01"
      :class="{ 'is-active--trail': isActive, 'nav__link--highlight': data.NodeAction === 'color:red' }"
      :data-nodekey="data.Xnodekey"
      class="nav__link"
    >
      <span v-if="data.IconName && showIcon" :class="data.IconName" class="nav__link-icon">{{ data.Value01 }}</span>
      <span
        v-else-if="data.DefaultImageOnPath && showIcon"
        class="nav__link-image"
        :style="{ 'background-image': 'url(' + getImageHandlerStyleSrc(data.DefaultImageOnPath, { width: 60 }) + ')' }"
      ></span>
      <span
        v-else-if="data.ImageOnPath && showIcon"
        class="nav__link-image"
        :style="{ 'background-image': 'url(' + getImageHandlerStyleSrc(data.ImageOnPath, { width: 60 }) + ')' }"
      ></span>
      <span class="nav__link-name">
        <span v-if="data.Xnodekey === 'MN000000010'">{{ data.DefaultValue }}</span>
        <span v-else>{{ data.Lanqkey01 }}</span>
      </span>
      <span class="nav__link-marker"></span>
    </router-link>

    <!-- For controls initiate an action and do not have an explicit url -->
    <a href="#" v-else :title="data.Lanqkey01" :data-nodekey="data.Xnodekey" @click.prevent="onClickItem(data)" class="nav__link">
      <span v-if="data.IconName && showIcon" :class="data.IconName" class="nav__link-icon"></span>
      <span
        v-else-if="data.DefaultImageOnPath && showIcon"
        class="nav__link-image"
        :style="{ 'background-image': 'url(' + getImageHandlerStyleSrc(data.DefaultImageOnPath, { width: 60 }) + ')' }"
      ></span>
      <span class="nav__link-name">
        <span v-if="data.Xnodekey === 'MN000000010'">{{ data.DefaultValue }}</span>
        <span v-else>{{ data.Lanqkey01 }}</span>
      </span>
      <span class="nav__link-marker"></span>
    </a>
  </li>
</template>

<script>
import Dropdown from '@/components/Dropdown';
import mixins from '@/shared/mixins/index';

export default {
  name: 'NavItem',

  mixins: [mixins],

  components: {
    Dropdown
  },

  props: {
    activeNode: {
      type: Object
    },

    data: {
      type: Object,
      required: true
    },

    showIcon: {
      type: Boolean,
      default: true,
      required: false
    }
  },

  computed: {
    isActionControl() {
      return this.isControlAction(this.data.XcontrolMother, this.data);
    },

    isActive() {
      if (!this.activeNode) {
        return false;
      }

      return this.data.Xnodekey === this.activeNode.Xnodekey;
    }
  },

  methods: {
    getImagePath(node) {
      console.log(node.DefaultImageOnPath);
      const path = this.getImageHandlerSrc(node.DefaultImageOnPath, { width: 60 });
      const pattern = /"/gi;
      let p = path.replace(pattern, '%22');
      return p;
    },

    onClickItem(node) {
      console.log('onclickitem', node);
      this.$store.commit('CLOSE_OFFCANVAS');

      this.requestNode(node.Xnodekey).then(response => {
        this.handleNode(response.data.RESPONSE);
      });
    }
  }
};
</script>
