/* eslint-disable */
import Vue from 'vue';
import i18n from './../i18n/index';

export default {
	CLEAR_CONTROL: function(state, payload) {
		let removed = state.visibleControls.filter(control => {
			return control !== payload.name;
		});
		state.visibleControls = removed;
	},

	CLEAR_CONTROLS_VISIBILITY: function(state, payload) {
		let filteredControls = state.visibleControls.filter(control => {
			return state.protectedControls.includes(control);
		});
		state.visibleControls = filteredControls;
	},

	CLOSE_OFFCANVAS: function(state, payload) {
		state.offcanvas.visible = false;
	},

	OPEN_OFFCANVAS: function(state, payload) {
		state.offcanvas.visible = true;
	},

	SET_ADDRESSES: function(state, payload) {
		state.addresses = payload.addresses;
	},

	SET_APP_DEBUG: function(state, payload) {
		state.app.debug.visible = payload.visible;
	},

	SET_APP_LANGUAGE: function(state, payload) {
		i18n.locale = payload.language;
		state.app.language = payload.language;
		Vue.prototype.$localStorage.set('languageCode', payload.language);
		Vue.prototype.$localStorage.set('outmenulanq', payload.language);
	},

	SET_APP_LOADING: function(state, payload) {
		state.app.loading = payload.loading;
	},

	SET_APP_VERSION: function(state, payload) {
		state.app.backendversion = payload.BACKENDVERSION;
	},

	SET_CONTROL: function(state, payload) {
		let isAvailable = false;
		state.visibleControls.forEach(control => {
			if (control === payload.name) {
				isAvailable = true;
			}
		});

		if (!isAvailable && payload.node.WebAction !== 'NOTHING' && payload.name.length > 0) {
			state.visibleControls.push(payload.name);
		}
	},

	SET_CURRENT_VIEW: function(state, payload) {
		state.app.currentView = payload.currentView;
	},

	SET_FIRST_NODE: function(state, payload) {
		state.firstNode = payload;
	},

	SET_IS_LOGIN_PAGE: function(state, payload) {
		state.app.isLoginPage = payload.isLoginPage;
	},

	SET_LOCAL_MESSAGE: function(state, payload) {
		const message = {};
		const languageCode = payload.language.toLowerCase();
		Vue.config.lang = languageCode;
		i18n.locale = languageCode;

		// Transform data for i18n response to get PLACEHOLDER:TRANSLATED_TEXT
		_.forEach(payload.data, item => {
			message[item.WebToken] = item.TextTranslate;
		});

		i18n.setLocaleMessage(languageCode, message);
	},

	SET_NODE: function(state, payload) {
		state.node = payload;
	},

	SET_PRIVACY_POLICY: function(state, payload) {
		state.user.HASREADPRIVACYMIP = payload.HASREADPRIVACYMIP === 'true' ? true : false;
		Vue.prototype.$session.set('HASREADPRIVACYMIP', state.user.HASREADPRIVACYMIP);
	},

	SET_USER: function(state, payload) {
		state.user.name = payload.NAME;
		state.user.userlogin = payload.USERLOGIN;
		state.user.userrole = payload.USERROLE;
		state.user.city = payload.CITY;
		state.user.country = payload.COUNTRY;
		state.user.phone1 = payload.PHONE01;
		state.user.phone2 = payload.PHONE02;
		state.user.lastlogdate = payload.LASTLOGDATE;
		state.user.createdate = payload.CREATEDATE;
		state.user.outcountry = payload.OUTCOUNRTY;
		state.user.outmenuelanq = payload.OUTMENUELANQ;
		state.user.outdoclanq = payload.OUTDOCLANQ;
		state.user.servername1 = payload.SERVERNAME1;
		state.user.SERVERVERIFICATION = payload.SERVERVERIFICATION;
		state.user.servercatalog = payload.SERVERCATALOG;
		state.user.HASREADPRIVACYMIP = payload.HASREADPRIVACYMIP === 'true' ? true : false;
    state.user.APPLICATION = payload.APPLICATION;
    state.user.ENVIRONMENT = payload.ENVIRONMENT;
		state.user.IDENTITYPROVIDER = payload.IDENTITYPROVIDER;

		Vue.prototype.$localStorage.set('outcountry', payload.OUTCOUNRTY);
		Vue.prototype.$localStorage.set('outmenulanq', payload.OUTMENUELANQ);
		Vue.prototype.$localStorage.set('outdoclanq', payload.OUTDOCLANQ);
		Vue.prototype.$localStorage.set('servername1', payload.SERVERNAME1);
		Vue.prototype.$localStorage.set('APPLICATION', payload.APPLICATION);
		Vue.prototype.$localStorage.set('ENVIRONMENT', payload.ENVIRONMENT);

		// Session
		Vue.prototype.$session.set('userlogin', payload.USERLOGIN);
		Vue.prototype.$session.set('userrole', payload.USERROLE);
		Vue.prototype.$session.set('name', payload.NAME);
		Vue.prototype.$session.set('HASREADPRIVACYMIP', state.user.HASREADPRIVACYMIP);
	},

	SET_SESSION_NUMBER: function(state, payload) {
		state.app.sessionNumber = payload.sessionNumber;
		Vue.prototype.$session.set('Sessionnumber', payload.sessionNumber);
	},

	TOGGLE_OFFCANVAS: function(state, payload) {
		state.offcanvas.visible = !state.offcanvas.visible;
	}
};
