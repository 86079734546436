<template>
  <footer class="app-footer" :class="'app-footer--' + variant">
    <div class="container">
      <el-row>
        <el-col :span="24">
          <p class="m-t-0" v-t="'BROWSER_REQUIREMENTS'"></p>
        </el-col>
      </el-row>

      <el-row>
        <el-col :sm="3" :md="2">
          <div class="app-footer__logo">
            <SVGLogo />
          </div>
        </el-col>
        <el-col :sm="13" :md="14">
          <p v-html="$t('MEIKO_ADDRESS')"></p>
          <ul class="list--horizontal m-t-0 m-b-0">
            <li v-for="legalLink in legalLinks" :key="legalLink.id">
              <a :href="legalLink.href" :title="$t(legalLink.code)" target="_blank">{{ $t(legalLink.code) }}</a>
            </li>
          </ul>
        </el-col>
        <el-col :sm="8" :md="8" class="m-t-1--xs">
          <p>
            <span v-t="'SESSION_NUMBER'">:</span>
            <span>{{ sessionNumber }}</span>
            <br />
            <span v-t="'WEB_VERSION'">:</span>
            <span>
              {{ version }}
              <span v-if="backendversion">/ {{ backendversion }}</span>
            </span>
          </p>
        </el-col>
      </el-row>
    </div>
  </footer>
</template>

<script>
import SVGLogo from '@/assets/img/logo-meiko.svg';

export default {
  name: 'AppFooter',

  components: {
    SVGLogo
  },

  props: {
    variant: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {
      legalLinks: [
        {
          id: 1,
          href: 'http://www.meiko.de/impressum/',
          code: 'IMPRINT'
        },
        {
          id: 2,
          href: 'http://www.meiko.de/haftungsausschluss/',
          code: 'DISCLAIMER'
        },
        {
          id: 3,
          href: 'http://www.meiko.de/datenschutz/',
          code: 'PRIVACY_POLICY'
        }
      ],
      version: process.env.VUE_APP_VERSION
    };
  },

  computed: {
    backendversion() {
      return this.$store.state.app.backendversion;
    },

    sessionNumber() {
      return this.$store.state.app.sessionNumber;
    }
  }
};
</script>

<style lang="scss">
.app-footer {
  border-top: 1px solid $c-light-gray;
  background-image: url('../../assets/img/bg-main.png');
  background-repeat: repeat;

  margin-top: 4rem;
  padding: 1.5rem 0;

  &--default {
    background-image: none;
    margin-top: 0rem;
  }

  p {
    color: $c-black;
    font-family: $font-brand;
    font-size: 0.8rem;
    line-height: 1.2rem;

    &:first-child {
      margin-top: 0;
    }

    @media screen and (max-width: $screen-md) {
      font-size: 0.7rem;
      line-height: 0.9rem;
    }
  }

  a {
    color: $c-black;
    font-size: 0.8rem;
    line-height: 1.2rem;
    text-decoration: underline;

    @media screen and (max-width: $screen-md) {
      font-size: 0.9rem;
      line-height: 1rem;
    }
  }

  @media screen and (max-width: $container-width) {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  &__logo {
    margin-right: 1rem;
    margin-bottom: 1rem;
    max-width: 70px;
  }
}
</style>
