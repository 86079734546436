/* eslint-disable no-unused-vars */
import API from './../../config/api';

const state = {
  loading: false,
  node: {
    GuiNodeCh: []
  },
  pagination: {
    currentPage: 1,
    pageSize: 20
  },
  sessions: {},
  totalItems: 0
};

const actions = {
  GET_OLD_SESSIONS({ commit, state, rootState }, payload) {
    return API.get('/GetOldSessions', {
      params: {
        data: {
          SESSIONNUMBER: rootState.app.sessionNumber,
          PAGELIMIT: payload.PAGELIMIT,
          PAGEOFFSET: payload.PAGEOFFSET,
          QUERYSTRING: payload.data
        }
      }
    })
      .then(response => {
        commit('SET_NODE', { node: response.data.RESPONSE });

        return response;
      })
      .catch(err => {
        console.log('GET_OLD_SESSIONS error', err);
        return err;
      });
  },

  SET_SESSION({ commit, state, rootState }, payload) {
    return API.get('/SetSession', {
      params: {
        data: {
          SESSIONNUMBER: rootState.app.sessionNumber,
          SESSIONNUMBEROLD: payload.sessionNumber
        }
      }
    })
      .then(response => {
        return response;
      })
      .catch(err => {
        console.log('SET SESSION error', err);
        return err;
      });
  }
};

const getters = {};

const mutations = {
  RESET_PAGINATION(state, payload) {
    state.pagination.currentPage = 1;
    state.pagination.pageSize = 20;
  },

  SET_NODE(state, payload) {
    state.node = payload.node;

    if (state.pagination.currentPage < 2) {
      state.totalItems = parseInt(payload.node.RowsCount);
    }
  },

  SET_PAGINATION(state, payload) {
    state.pagination.currentPage = payload.currentPage;
    state.pagination.pageSize = payload.pageSize;
  },

  SET_TOTAL_ITEMS(state, payload) {
    state.totalItems = payload.totalItems;
  }
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
};
