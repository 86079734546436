/* eslint-disable object-shorthand */
/* eslint-disable no-empty-pattern */
/* eslint-disable func-names */
import Vue from 'vue';
import API from '@/config/api';
import { get } from 'lodash';
import { getLanguage } from '@/services/browser';

export default {
  CHANGE_PASSWORD({}, payload) {
    return API.get('/UserPwd', {
      params: {
        data: {
          USERPWD: payload.password
        }
      }
    })
      .then(response => response)
      .catch(err => err);
  },

  CREATE_USER({}, payload) {
    const USER = {
      ADRESS: payload.street,
      CITY: payload.city,
      COMPANY: payload.selectedCompany,
      COUNTRY: payload.selectedCountry,
      USERLOGIN: payload.email,
      NAME: payload.fullname,
      HASREADPRIVACYMIP: payload.privacy_policy,
      OUTMENUELANQ: getLanguage(),
      PASSWORD01: payload.password,
      PHONE01: payload.Phone1,
      ZIPCODE: payload.postal
    };

    console.log('CREATE_USER', USER, payload);

    return API.get('/UserCreate', {
      params: {
        data: USER
      }
    })
      .then(response => response)
      .catch(err => err);
  },

  GET_ADDRESSES({ commit }) {
    return API.get('/Adresses')
      .then(result => {
        commit('SET_ADDRESSES', {
          addresses: result.data.RESPONSE
        });
        return result;
      })
      .catch(err => err);
  },

  GET_COUNTRIES() {
    const language = getLanguage();

    return API.get('/Countrys', {
      params: {
        data: {
          MENUELANQ: language
        }
      }
    })
      .then(response => response)
      .catch(err => err);
  },

  GET_FIRST_NODE({ commit }, payload = {}) {
    const params = Object.assign({}, payload);
    params.SESSIONNUMBER = Vue.prototype.$session.get('Sessionnumber') || '';

    commit('SET_APP_LOADING', {
      loading: true
    });

    return API.get('/GetFirstNode', {
      params: {
        data: {
          ...params
        }
      }
    })
      .then(response => {
        const node = response.data.RESPONSE;
        commit('SET_APP_LOADING', {
          loading: false
        });
        commit('SET_FIRST_NODE', node);
        commit('SET_SESSION_NUMBER', {
          sessionNumber: node.Sessionnumber
        });

        return response;
      })
      .catch(err => {
        commit('SET_APP_LOADING', {
          loading: false
        });

        Vue.prototype.$notify.error({
          title: 'Error',
          message: get(err, 'response.data') || err.message,
          duration: 0
        });

        return err;
      });
  },

  GET_NODE({ commit, state }, payload) {
    const { nodeKey, nodeInput } = payload;

    return API.get('/GetNode', {
      params: {
        data: {
          SESSIONNUMBER: state.app.sessionNumber,
          NODENUMBER: nodeKey,
          NODEINPUT: nodeInput
        }
      }
    })
      .then(response => {
        commit('SET_NODE', response.data.RESPONSE);
        return response;
      })
      .catch(err => {
        commit('pages/HIDE_LOADING');
        return err;
      });
  },

  GET_NODE_LEAN({ commit, state }, payload) {
    const { nodeKey, nodeInput } = payload;

    return API.get('/GetNodeLean', {
      params: {
        data: {
          SESSIONNUMBER: state.app.sessionNumber,
          NODENUMBER: nodeKey,
          NODEINPUT: nodeInput
        }
      }
    })
      .then(response => {
        commit('SET_NODE', response.data.RESPONSE);
        return response;
      })
      .catch(err => {
        commit('pages/HIDE_LOADING');
        return err;
      });
  },

  GET_TOOLTIP({}, payload) {
    const toolTipNumber = payload.InfoTooltipNumber;
    const menueLanguage = payload.MenueLanguage;

    return API.get('/ToolTip', {
      params: {
        data: {
          LANQKEY: toolTipNumber,
          MENUELANQ: menueLanguage
        }
      }
    })
      .then(response => response)
      .catch(err => err);
  },

  GET_TRANSLATION({ state }) {
    const language = Vue.prototype.$localStorage.get('languageCode') || state.app.language || getLanguage();

    return API.get('/TranslateWeb', {
      params: {
        data: {
          MENUELANQ: language,
          SESSIONNUMBER: state.app.sessionNumber
        }
      }
    })
      .then(response => response)
      .catch(err => err);
  },

  GET_USER({ commit }) {
    return API.get('/UserInfo')
      .then(result => {
        const RESPONSE = get(result, 'data.RESPONSE');
        if (RESPONSE) {
          commit('SET_USER', RESPONSE);
          commit('SET_APP_VERSION', RESPONSE);
        }
        return result;
      })
      .catch(err => err);
  },

  SET_PRIVACY_POLICY({ commit }, payload) {
    const { HASREADPRIVACYMIP } = payload;

    return API.post('/UserSetPrivayMip', {
      data: { HASREADPRIVACYMIP }
    })
      .then(response => {
        commit('SET_PRIVACY_POLICY', { HASREADPRIVACYMIP: true });
        return response;
      })
      .catch(err => err);
  }
};
