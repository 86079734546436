import { forEach, get } from 'lodash';
import axios from 'axios';
import QUERYSTRING from 'qs';

export default {
  computed: {
    debug() {
      return this.$store.state.app.debug;
    }
  },

  methods: {
    /**
     * Document handler url
     * @param {string} path
     */
    getDocumentHandlerSrc(path) {
      const params = {
        DOCUMENTPATH: path,
        SESSIONNUMBER: this.$session.get('Sessionnumber'),
        token: this.$session.get('token')
      };
      const paramsString = JSON.stringify(params);

      return this.$store.state.app.documentLoaderPath + paramsString;
    },

    /**
     * Image handler source without data object
     * used inside html style attributes
     * @param {string} path
     * @param {Object} size
     * @returns {string}
     */
    getImageHandlerStyleSrc(path, size = {}) {
      if (path === null || '') {
        return '';
      }

      const params = {
        imagepath: path,
        height: size.height,
        maxheight: size.maxheight,
        maxwidth: size.maxwidth,
        width: size.width,
        token: this.$session.get('token')
      };
      const paramsString = QUERYSTRING.stringify(params);

      if (this.getImageExtension(path) === 'svg') {
        return this.$store.state.app.svgLoaderPath + paramsString;
      }

      return this.$store.state.app.imageLoaderStyle + paramsString;
    },

    /**
     * Image handler url
     * @param   {string} path
     * @param   {Object} size
     * @returns {string}
     */
    getImageHandlerSrc(path, size = {}) {
      if (path === null || '') {
        return '';
      }

      const params = {
        IMAGEPATH: path,
        height: size.height,
        maxheight: size.maxheight,
        maxwidth: size.maxwidth,
        width: size.width,
        token: this.$session.get('token'),
        t: Date.now()
      };
      const paramsString = JSON.stringify(params);

      if (this.getImageExtension(path) === 'svg') {
        return this.$store.state.app.svgLoaderPath + paramsString;
      }

      return this.$store.state.app.imageLoaderPath + paramsString;
    },

    /**
     * Image extension
     * @param {string} path
     */
    getImageExtension(path) {
      let extension = path.split('.');
      extension = extension[extension.length - 1];

      return extension.toLowerCase();
    },

    /**
     * Report handler url
     * @param {string} session
     * @param {string} typ
     * @returns {string} report handler url
     */
    getReportHandlerUrl(session, typ = 'SESSPDF') {
      const params = {
        REPORTTYPE: typ,
        SESSIONNUMBER: session,
        token: this.$session.get('token')
      };
      const paramsString = JSON.stringify(params);

      return this.$store.state.app.reportLoaderPath + paramsString;
    },

    /**
     * Get object site
     * @param {Object} obj
     * @returns {int}
     */
    getObjectSize(obj) {
      let size = 0;

      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          size++;
        }
      }

      return size;
    },

    handleAppLanguage(node) {
      const appLanguage = this.$store.state.app.language;
      const MenueLanguage = get(node, 'MenueLanguage');

      if (MenueLanguage !== appLanguage) {
        this.$store.commit('SET_APP_LANGUAGE', { language: MenueLanguage });
        this.$store.dispatch('GET_TRANSLATION').then(response => {
          const responseData = get(response, 'data.RESPONSE', []);
          this.$store.commit('SET_LOCAL_MESSAGE', {
            language: MenueLanguage,
            data: responseData
          });
        });
      }
    },

    /**
     * Breadcrumb
     * @param {Object} node
     */
    handleBreadcrumb(node) {
      const IsBreadCrumb = get(node, 'IsBreadCrumb');
      const Xnodekey = get(node, 'Xnodekey');

      if (IsBreadCrumb === 'Y') {
        const nodes = [];
        let found = false;

        this.$store.state.breadcrumb.nodes.forEach(breadcrumbNode => {
          if (breadcrumbNode.Xnodekey !== Xnodekey && !found) {
            nodes.push(breadcrumbNode);
          } else {
            found = true;
          }
        });

        nodes.push(node);
        this.$store.commit('breadcrumb/SET_NODES', { nodes });
      }
    },

    /**
     * Main function to handle first node
     * @param {Object} node
     */
    handleFirstNode(node) {
      const WebLoadNode = get(node, 'WebLoadNode', '');
      const XcontrolMother = get(node, 'XcontrolMother');
      const Xnodekey = get(node, 'Xnodekey');
      const nextNodes = WebLoadNode.split(';');

      // Remove last ';' or empty string
      if (nextNodes[nextNodes.length - 1] === ';' || nextNodes[nextNodes.length - 1] === '') {
        nextNodes.pop();
      }

      nextNodes.sort();

      if (XcontrolMother === this.$store.state.protectedControls[0]) {
        const lastNodeToLoad = nextNodes[nextNodes.length - 1];

        // Redirect to inital component if user do not hit a specific node like /pages
        if (!this.$route.params.id) {
          this.$router.push({ name: 'Pages', params: { id: lastNodeToLoad } });

          // Remove last node to load to prevent double request
          nextNodes.pop();
        } else {
          // Remove default node and replace it with current node in url
          nextNodes.pop();
          nextNodes.push(this.$route.params.id);

          // For breadcrumb it is necessary to get the inital node
          this.requestNode(lastNodeToLoad).then(response => {
            this.handleBreadcrumb(response.data.RESPONSE);
          });
        }
      }

      // Prepare concurrent requests
      const nextNodesRequests = [];
      nextNodes.forEach(nextNode => {
        nextNodesRequests.push(this.requestNode(nextNode));
      });

      this.$store.commit('pages/SET_PAGES_LOADING', { loading: true, nodeKey: Xnodekey });

      // Execute concurrent requests
      axios.all(nextNodesRequests).then(
        axios.spread((...results) => {
          results.forEach(result => {
            const RESPONSE = get(result, 'data.RESPONSE', {});
            this.handleNode(RESPONSE);
          });

          this.$store.commit('pages/HIDE_LOADING');
        })
      );
    },

    handleMainNavigation(node) {
      const XcontrolMother = get(node, 'XcontrolMother', '');
      this.$store.commit('pages/SET_NODE', { node: node });

      if (this.$store.state.pages.node.Xlevel <= this.mainXlevel && XcontrolMother !== 'WEBFRONTPAGE') {
        this.$store.commit('pages/SET_NODE_MAIN', { node: node });
        this.$localStorage.set('activeMainNode', JSON.stringify(node));
      }
    },

    /**
     * Request next nodes in current node
     * @param {Object} node
     */
    handleNextNodes(node) {
      const WebLoadNode = get(node, 'WebLoadNode', null);
      const XcontrolMother = get(node, 'XcontrolMother');

      if (WebLoadNode === null) {
        return false;
      }

      const nextNodes = WebLoadNode.split(';');

      // Remove last ';' or empty string
      if (nextNodes[nextNodes.length - 1] === ';' || nextNodes[nextNodes.length - 1] === '') {
        nextNodes.pop();
      }

      nextNodes.forEach(nextNode => {
        // Switch request function to load next nodes
        if (XcontrolMother === 'WEBFRONTPAGE') {
          this.requestNode(nextNode).then(result => {
            const RESPONSE = get(result, 'data.RESPONSE', {});
            this.handleNode(RESPONSE);
          });
        } else {
          this.requestNodeLean(nextNode).then(result => {
            const RESPONSE = get(result, 'data.RESPONSE', {});
            this.handleNode(RESPONSE);
          });
        }
      });
    },

    /**
     * Main function to handle requested node
     * @param {Object} node
     * @param {Boolean} clear
     */
    handleNode(node, clear = true) {
      const XcontrolMother = get(node, 'XcontrolMother', '');
      const XcontrolInput = get(node, 'XcontrolInput', '');
      const Xposition = get(node, 'Xposition', '');
      const controlName = XcontrolMother ? XcontrolMother : XcontrolInput;
      console.log('1. handleNode', controlName, XcontrolMother);

      // this.handleNodeProcessing(node);

      if (controlName === 'CLEAR' || controlName === 'MNURESETUSER') {
        console.log('Backend says: "Reload page"', controlName);

        // Refresh current page
        if (this.$route.params.id === this.$store.getters.INITIAL_NODE) {
          console.log('Reload inital component', node.WebLoadNode, controlName);
        }

        // Go to start page
        this.$router.push({ path: '/pages/' });

        // Update Navigation
        this.refreshWebribbon();

        // Load next nodes
        this.handleNextNodes(node);

        // Get translation for current menu language
        this.handleAppLanguage(node);

        return false;
      }

      if (controlName) {
        // For all main controls and non action controls
        if (!this.isControlProtected(controlName) && !this.isControlSecondary(controlName) && !this.isControlAction(controlName)) {
          if (clear) {
            this.$store.commit('CLEAR_CONTROLS_VISIBILITY');
          }

          // Set current control
          this.$store.commit('SET_CONTROL', {
            name: controlName,
            node
          });
        }

        // For all main controls or not protected controls
        if (this.isControlSecondary(controlName) || !this.isControlProtected(controlName)) {
          // Set node data for current control
          this.$store.commit(`${controlName.toLowerCase()}/SET_NODE`, {
            node
          });
        }
      }

      // Exception für navigation
      if (controlName === 'WEBRIBBON') {
        if (Xposition === '10000') {
          this.$store.commit(`${controlName.toLowerCase()}/SET_NODE`, {
            node
          });
        } else if (Xposition === '20000') {
          this.$store.commit(`${controlName.toLowerCase()}/SET_INFO`, {
            node
          });
        }
      }

      // Take further steps for controls who initiate an action
      if (this.isControlAction(controlName)) {
        const isControlVisible = this.$store.state[`${controlName.toLowerCase()}`].visible;
        if (!isControlVisible) {
          // Open current control dependant on the request
          this.$store.commit(`${controlName.toLowerCase()}/OPEN`, {
            node,
            visible: true
          });
        }
      }

      this.handleBreadcrumb(node);
      this.handleNextNodes(node);
    },

    /**
     * Subfunction to handle node processing
     * @param {Object} node
     */
    handleNodeProcessing(node) {
      const WebMessage = get(node, 'WebMessage');
      const InfoMessage = get(node, 'InfoMessage');
      const WebAction = get(node, 'WebAction', '');

      if (WebMessage) {
        this.$notify({
          message: WebMessage,
          duration: 8000,
          type: 'info',
          dangerouslyUseHTMLString: true,
          showClose: true
        });
      }

      if (InfoMessage) {
        this.$store.commit('dialog/OPEN_DIALOG', {
          content: InfoMessage,
          visible: true
        });
      }

      if (WebAction === 'NOTHING') {
        return false;
      }

      if (WebAction === 'SESSIONRELOAD') {
        this.$router.go({ path: '/pages/' });

        this.handleNextNodes(node);

        return false;
      }
    },

    /**
     * Is component registred yet
     * @param {String} name
     * @returns {Boolean}
     */
    isComponentRegistered(name) {
      let result = false;
      forEach(this.$options.components, (value, key) => {
        if (key === name) {
          result = true;
        }
      });

      return result;
    },

    /**
     * Do control perform action.
     * For the result compare the control name with known action control names in [actionControls]
     * and compare the node key with known action nodes in [actionNodes].
     * ToDo: Use only the node object and do the same with other functions in this file.
     *
     * @param {string} name
     * @param {Object} node
     * @returns {Boolean}
     */
    isControlAction(name = '', node = {}) {
      const Xnodekey = get(node, 'Xnodekey');
      let isActionControl = false;

      if (name.length) {
        this.$store.state.actionControls.forEach(control => {
          if (control === name) {
            isActionControl = true;
          }
        });
      }

      if (Xnodekey) {
        this.$store.state.actionNodes.forEach(control => {
          if (control === Xnodekey) {
            isActionControl = true;
          }
        });
      }

      return isActionControl;
    },

    /**
     * Is control protected
     * @param {string} name
     * @returns {Boolean}
     */
    isControlProtected(name) {
      let isProtected = false;

      this.$store.state.protectedControls.forEach(control => {
        if (control === name) {
          isProtected = true;
        }
      });

      return isProtected;
    },

    /**
     * Is secondary control
     * @param {string} name
     * @returns {Boolean}
     */
    isControlSecondary(name) {
      let isSecondary = false;

      this.$store.state.secondaryControls.forEach(control => {
        if (control === name) {
          isSecondary = true;
        }
      });

      return isSecondary;
    },

    /**
     * Is control visible
     * @param {string} name
     * @returns {Boolean}
     */
    isControlVisible(name) {
      let result = false;
      this.$store.state.visibleControls.forEach(control => {
        if (control === name) {
          result = true;
        }
      });
      return result;
    },

    /**
     * Refresh navigation
     * @returns {void}
     */
    refreshWebribbon() {
      this.$store.commit('SET_APP_LOADING', {
        loading: true
      });

      const webribbon = [];
      webribbon.push(this.$store.state.webribbon.node.Xnodekey);
      webribbon.push(this.$store.state.webribbon.info.Xnodekey);

      const requests = [];
      webribbon.forEach(node => {
        requests.push(this.requestNode(node));
      });

      axios
        .all(requests)
        .then(
          axios.spread((node, info) => {
            const nodeResponse = get(node, 'data.RESPONSE', {});
            const infoResponse = get(info, 'data.RESPONSE', {});
            this.handleNode(nodeResponse);
            this.handleNode(infoResponse);
            this.$store.commit('SET_APP_LOADING', {
              loading: false
            });
          })
        )
        .catch(() => {
          this.$store.commit('SET_APP_LOADING', {
            loading: false
          });
        });
    },

    /**
     * Main function to request specific node
     * @param {string} nodeKey
     * @param {string} nodeInput
     */
    requestNode(nodeKey, nodeInput = '') {
      return this.$store
        .dispatch('GET_NODE', {
          nodeKey,
          nodeInput
        })
        .then(response => {
          // Transform response from backend for further transactions
          const result = get(response, 'data.RESPONSE');
          const GuiNodeCh = get(response, 'data.RESPONSE.GuiNodeCh');

          if (result) {
            // Handle node to show for example messages in dialog
            this.handleNodeProcessing(response.data.RESPONSE);

            if (GuiNodeCh && this.getObjectSize(GuiNodeCh)) {
              /* eslint-disable-next-line */
              forEach(GuiNodeCh, child => {
                child = this.transformNode(child);
              });
            }
          }

          return response;
        })
        .catch(err => {
          this.$store.commit('pages/HIDE_LOADING');

          const errorMsg = 'Es ist ein Fehler beim Laden aufgetreten.';

          this.$message({
            type: 'error',
            showClose: true,
            message: errorMsg
          });

          // Redirect if node is not available
          if (this.$store.getters.INITIAL_NODE) {
            this.$router.push({
              name: 'Pages',
              params: {
                id: this.$store.getters.INITIAL_NODE
              }
            });

            window.location.reload();
          } else {
            this.$router.push({
              name: 'Login'
            });
          }

          return err;
        });
    },

    /**
     * Main function to request node without any additional actions
     * @param {string} nodeKey
     * @param {string} nodeInput
     */
    requestNodeLean(nodeKey, nodeInput = '') {
      return this.$store
        .dispatch('GET_NODE_LEAN', {
          nodeKey,
          nodeInput
        })
        .then(response => response)
        .catch(err => err);
    },

    /**
     * Recursive node transformation
     * @param {Object} current node
     * @returns {Object} transformed node
     */
    transformNode(node) {
      node.Show = get(node, 'Show', '') === '1' ? true : false;
      node.Valid = get(node, 'Valid', '') === '1' ? true : false;
      const GuiNodeCh = get(node, 'GuiNodeCh');

      if (GuiNodeCh && this.getObjectSize(GuiNodeCh)) {
        /* eslint-disable-next-line */
        forEach(GuiNodeCh, child => {
          child = this.transformNode(child);
        });
      }

      return node;
    }
  }
};
