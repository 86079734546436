/* eslint-disable no-undef */
import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import store from './../store/index';
import { checkIfLoginPage } from './../services/auth';

Vue.use(Router);

const router = new Router({
  routes,

  linkActiveClass: 'is-active',

  linkExactActiveClass: 'is-active--exact'
});

/**
 * Process for each route to check for authentification and redirection
 * @param  {Object} to
 * @param  {Object} from
 * @param  {Function} next
 * @return {void}
 */
router.beforeEach((to, from, next) => {
  const isLoginPage = checkIfLoginPage(to, from);
  store.commit('SET_IS_LOGIN_PAGE', { isLoginPage: isLoginPage });

  // Redirect user to login page if not authenticated
  if (to.meta.requiresAuth) {
    const token = Vue.prototype.$session.get('token');

    if (!token || !token.length) {
      next({ path: '/login' });
    }
  }

  next();
});

router.afterEach(to => {
  // Send event to analytics
  if (window.ga) {
    ga('set', 'page', to.fullPath);
    ga('send', 'pageview');
  }
});

export default router;
