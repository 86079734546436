<!--
	@component:	DIALOG
	@desc:		This component handle multiple dialogs.
	@author: 	Yannick Herzog, info@hit-services.net
	@created:	2018-08-16
	@modified: 	2018-08-16
 -->
<template>
  <div class="app-dialog__container">
    <!-- Loop over all dialog instances to show multiple dialogs -->
    <el-dialog
      v-for="dialog in dialogInstances"
      :key="dialog.id"
      :visible.sync="dialog.visible"
      :fullscreen="dialog.fullscreen"
      :title="dialog.title"
      :width="dialog.width"
      :append-to-body="true"
      :modal-append-to-body="false"
      @closed="onDialogClose(dialog)"
    >
      <!-- Make sure to register component first -->
      <component :is="dialog.loadComponent" v-if="dialog.loadComponent" :data="dialog.data"></component>

      <span
        v-if="dialog.content"
        v-html="dialog.content"
        v-loading="dialog.loading"
        :class="{ 'text-center d-block': dialog.textCenter }"
      ></span>
      <span v-else v-loading="dialog.loading"></span>
    </el-dialog>
  </div>
</template>

<script>
import FormChangePassword from '@/components/Form/FormChangePassword';
import FormChangeUser from '@/components/Form/FormChangeUser';
import FormPrivacyPolicy from '@/components/Form/FormPrivacyPolicy';

export default {
  name: 'Dialog',

  computed: {
    dialogInstances() {
      return this.$store.state.dialog.instances;
    }
  },

  components: {
    FormChangePassword,
    FormChangeUser,
    FormPrivacyPolicy
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      console.log('Dialog mounted');
    },

    onDialogClose(dialog) {
      console.log('onDialogClose', dialog);
      this.$store.commit('dialog/CLOSE_DIALOG', { instance: dialog });
    }
  }
};
</script>
