<template>
  <el-form :model="form" :rules="rules" ref="form" label-width="140px" :label-position="labelPosition" @submit.prevent="submitForm">
    <el-form-item :label="$t('EMAIL')" prop="username">
      <el-input type="text" v-model="form.username" auto-complete="off"></el-input>
    </el-form-item>

    <el-form-item class="text-right">
      <el-button type="text" @click="close" class="m-r-1">{{ $t('CANCEL') }}</el-button>
      <el-button type="primary" @click="submitForm('form')">{{ $t('CHANGE_USER') }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { get } from 'lodash';
import * as Browser from '@/services/browser';
import mixins from '@/shared/mixins/index';

export default {
  name: 'FormChangeUser',

  mixins: [mixins],

  data() {
    return {
      form: {
        username: ''
      },
      rules: {
        username: [{ required: true, type: 'email', message: this.$t('PLEASE_FILL_OUT_MANDATORY_FIELDS'), trigger: 'blur' }]
      },
      labelPosition: 'top'
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    close() {
      this.$store.commit('dialog/CLOSE_DIALOG');
    },

    init() {
      if (Browser.isDeviceMD() || Browser.isDeviceLG() || Browser.isDeviceXL()) {
        this.labelPosition = 'left';
      }
    },

    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$session.remove('Sessionnumber');
          this.$store.dispatch('GET_FIRST_NODE', { USERNAME: this.form.username }).then(result => {
            const RESPONSE = get(result, 'data.RESPONSE');
            if (RESPONSE) {
              this.handleMainNavigation(RESPONSE);
              this.handleFirstNode(RESPONSE);
            }
            this.close();
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
