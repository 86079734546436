<template>
  <div
    v-loading.fullscreen.lock="loading"
    :element-loading-text="text"
    :element-loading-spinner="icon"
    :element-loading-background="background"
  ></div>
</template>

<script>
export default {
  name: 'AppLoading',

  data() {
    return {};
  },

  computed: {
    background() {
      return this.$store.state.app.loadingBackground;
    },

    icon() {
      return this.$store.state.app.loadingIcon;
    },

    loading() {
      return this.$store.state.app.loading;
    },

    text() {
      return this.$store.state.app.loadingText;
    }
  }
};
</script>
