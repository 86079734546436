/* eslint-disable */
import Vue from 'vue';
import API from '@/config/api';

const state = {
  isLoading: false,
  token: null
};

const getters = {
  isLoading: state => state.isLoading,

  token: state => state.token
};

const actions = {
	async LOGIN({ commit }, payload) {
    const {email, password} = payload;
    if (!email || !password) {
      throw new Error('Missing authentication credentials');
    }

    try {
      commit('SET_LOADING', true);
      const result = await API.get('/Login', {
        params: {
          data: {
            USERNAME: email,
            USERPWD: password
          }
        }
      });
      const {data} = result;
      if (data) {
        const {RESPONSE} = data;
        if (RESPONSE) {
          commit('SET_AUTH', { token: RESPONSE });
        }
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      commit('SET_LOADING', false);
    }
	}
};

const mutations = {
  CLEAR_AUTH(state) {
    state.token = null;

    Vue.prototype.$localStorage.remove('outcountry');
    Vue.prototype.$localStorage.remove('outmenulanq');
    Vue.prototype.$localStorage.remove('outdoclanq');
    Vue.prototype.$localStorage.remove('activeMainNode');
    Vue.prototype.$localStorage.remove('servername1');
    Vue.prototype.$localStorage.remove('SERVERVERIFICATION');
    Vue.prototype.$localStorage.remove('languageCode');

    Vue.prototype.$session.remove('name');
    Vue.prototype.$session.remove('token');
    Vue.prototype.$session.remove('userlogin');
    Vue.prototype.$session.remove('userrole');
    Vue.prototype.$session.remove('HASREADPRIVACYMIP');
    Vue.prototype.$session.remove('Sessionnumber');

    Vue.prototype.$http.defaults.headers.common['Authorization'] = '';
  },

	SET_AUTH: function(state, payload) {
		state.token = payload.token;
  },

  SET_LOADING: function(state, payload) {
		state.isLoading = payload;
	}
};

export default {
	namespaced: true,
	actions,
	getters,
	mutations,
	state
};

export const MODULE_NAME = 'auth';
