/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import { getNodeByKey } from '@/services/utils';
import store from '@/store/index';

const state = {
  indicator: {
    loadingBackground: 'rgba(255,255,255,0.8)',
    IndicatorIcon: '',
    IndicatorName: '',
    Message: '',
    longIntervalIndicators: ['INDICATOR06']
  },
  loading: false,
  loadingBarIntervalID: null,
  node: {
    GuiNodeCh: [],
    Xnodekey: ''
  },
  nodeMain: {
    GuiNodeCh: [],
    Xnodekey: ''
  },
  observer: null,
  totalItems: 0
};

const mutations = {
  HIDE_LOADING(state) {
    // Clear interval for loading bar
    if (state.loadingBarIntervalID) {
      window.clearInterval(state.loadingBarIntervalID);
    }
    state.loading = false;

    if (state.observer) {
      state.observer.disconnect();
    }
  },

  SET_INDICATOR(state, payload) {
    state.indicator.IndicatorIcon = payload.IndicatorIcon || '';
    state.indicator.IndicatorName = payload.IndicatorName || '';
    state.indicator.Message = payload.Message || '';
  },

  SET_NODE(state, payload) {
    state.node = payload.node;
  },

  SET_NODE_MAIN(state, payload) {
    state.nodeMain = payload.node;
  },

  SET_PAGES_LOADING(state, payload) {
    state.loading = payload.loading;

    if (payload.nodeKey) {
      const indicator = getLoadingIndicator(payload.nodeKey);

      state.indicator.IndicatorIcon = indicator.IndicatorIcon || '';
      state.indicator.IndicatorName = indicator.IndicatorName || '';
      state.indicator.Message = indicator.Message || '';
    }

    if (state.loadingBarIntervalID) {
      window.clearInterval(state.loadingBarIntervalID);
    }
    handleLoadingIndicator(state);

    const appContent = document.querySelector('.app-content');
    if (appContent && 'IntersectionObserver' in window) {
      registerObserver();
    }
  }
};

/**
 * Handle loading indicator content like text and icon for the page loading spinner.
 * The circle icon is an SVG coming from the corresponding Element UI template.
 * If there is an custom icon we use this class otherwise the SVG will be rendered.
 *
 * @param {Object} currentState
 */
function handleLoadingIndicator(currentState) {
  // Element UI Circle from element-ui/packages/loading/src/loading.vue
  const loadingCircleIcon = `<svg class="circular" viewBox="25 25 50 50">
  <circle class="path" cx="50" cy="50" r="20" fill="none" /></svg>`;

  const loadingSpinner = document.querySelector('.main-content-inner  > .el-loading-mask .el-loading-spinner');

  // Set the icon as svg or icon font
  if (loadingSpinner) {
    let iconHtml = '';

    if (currentState.indicator.longIntervalIndicators.includes(currentState.indicator.IndicatorName)) {
      // Set progress for long interval indicators
      const updateInterval = 2000;
      const maxRuntime = 60000;
      let currentRuntime = updateInterval;
      let progress = (currentRuntime * 100) / maxRuntime;
      iconHtml = getLoadingBarIcon(progress);

      state.loadingBarIntervalID = setInterval(() => {
        // Clear interval if max runtime has been reached
        if (currentRuntime > maxRuntime) {
          window.clearInterval(state.loadingBarIntervalID);
        }

        currentRuntime += updateInterval;
        progress = (currentRuntime * 100) / maxRuntime;
        const loadingBarIcon = getLoadingBarIcon(progress);
        iconHtml = loadingBarIcon;

        // Set HTML
        loadingSpinner.innerHTML = `${iconHtml} <p class="el-loading-text">${currentState.indicator.Message}</p>`;
      }, updateInterval);
    } else if (currentState.indicator.IndicatorIcon.length) {
      iconHtml = `<i class="${currentState.indicator.IndicatorIcon}"></i>`;
    } else {
      iconHtml = loadingCircleIcon;
    }
    loadingSpinner.innerHTML = `${iconHtml} <p class="el-loading-text">${currentState.indicator.Message}</p>`;
  }
}

function getLoadingBarIcon(percent = 50, width = 300, height = 20) {
  const progressWidth = width * (percent / 100);

  return `<svg width="${width}px" height="${height}px" viewBox="0 0 ${width} ${height}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns: xlink="http://www.w3.org/1999/xlink">
    <g id="ProgressBar" stroke="none" stroke-width="0" fill="none" fill-rule="evenodd">
      <rect id="ProgressBar-Rectangle-Outter"
        fill="#F3F3F3" x="1" y="1" width="${width}" height="${height}" rx="10"></rect>
      <rect id="ProgressBar-Rectangle-Inner"
        stroke="#979797" fill="#009fe3" x="1" y="1" width="${progressWidth}" height="${height}" rx="10"></rect>
    </g>
  </svg>`;
}

/**
 * Get loading indicator for current node key
 *
 * @param {String} nodeKey
 * @returns {Object} indicator
 */
function getLoadingIndicator(nodeKey) {
  let indicator = {};
  const node = getNodeByKey(store.state.node, nodeKey);

  if (node) {
    if (node.Indicator) {
      indicator = node.Indicator;
    }
  }

  return indicator;
}

function registerObserver() {
  const spinnerTop = 21;

  const appContent = document.querySelector('.app-content');
  let loadingSpinner = document.querySelector('.main-content-inner  > .el-loading-mask .el-loading-spinner');

  if (loadingSpinner) {
    const appHeaderHeight = document.querySelector('.app-header').clientHeight;
    const appNavigationHeight = document.querySelector('.app-navigation').clientHeight;
    const appBreadcrumbHeight = document.querySelector('.app-breadcrumb').clientHeight;
    const appFooterHeight = document.querySelector('.app-footer').clientHeight;

    const top = appHeaderHeight + appNavigationHeight + appBreadcrumbHeight;
    const bottom = appFooterHeight;

    const config = { root: null, rootMargin: `-${top}px 0px -${bottom}px 0px`, threshold: 0 };

    let isLeaving = false;
    let scrollY = 0;

    state.observer = new IntersectionObserver(entries => {
      loadingSpinner = document.querySelector('.main-content-inner > .el-loading-mask .el-loading-spinner');

      entries.forEach(entry => {
        if (entry.isIntersecting) {
          isLeaving = true;

          // Sticky loading spinner
          loadingSpinner.style.position = 'fixed';
          loadingSpinner.style.top = '50%';
          loadingSpinner.style.left = 0;
          /* eslint-disable-next-line */
          scrollY = window.scrollY;
        } else if (isLeaving) {
          isLeaving = false;

          // Let loading spinner go away
          loadingSpinner.style.position = 'absolute';
          loadingSpinner.style.top = `${spinnerTop * 2}px`;
          if (scrollY > window.scrollY) {
            console.log('dir up');

            loadingSpinner.style.bottom = 'auto';
            loadingSpinner.style.top = `${spinnerTop * 2}px`;
          } else {
            console.log('dir down');

            loadingSpinner.style.bottom = '0';
            loadingSpinner.style.top = 'auto';
          }
          /* eslint-disable-next-line */
          scrollY = window.scrollY;
        }
      });
    }, config);

    if (state.loading) {
      state.observer.observe(appContent);
    } else {
      state.observer.disconnect();
    }

    console.log('loadingSpinner', loadingSpinner, top, bottom, spinnerTop);
  }
}

export default {
  namespaced: true,
  mutations,
  state
};
