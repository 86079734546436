/* eslint-disable no-unused-vars */
import API from './../../config/api';

const state = {
  node: {
    GuiNodeCh: []
  },
  pagination: {
    currentPage: 1,
    pageSize: 20,
    pageSizes: [25, 50, 100]
  },
  totalItems: 0
};

const actions = {
  GET_MACHINES({ commit, state, rootState }, payload) {
    return API.get('/MachinesSearch', {
      params: {
        data: {
          SESSIONNUMBER: rootState.app.sessionNumber,
          PAGELIMIT: payload.PAGELIMIT,
          PAGEOFFSET: payload.PAGEOFFSET,
          SEARCHSTRING: payload.SEARCHSTRING
        }
      }
    })
      .then(response => {
        return response;
      })
      .catch(err => {
        return err;
      });
  }
};

const getters = {};

const mutations = {
  RESET_PAGINATION(state, payload) {
    state.pagination.currentPage = 1;
    state.pagination.pageSize = 20;
  },

  SET_NODE(state, payload) {
    state.node = payload.node;
    if (state.pagination.currentPage < 2) {
      state.totalItems = parseInt(payload.node.RowsCount);
    }
  },

  SET_PAGINATION(state, payload) {
    state.pagination.currentPage = payload.currentPage;
    state.pagination.pageSize = payload.pageSize;
  },

  SET_TOTAL_ITEMS(state, payload) {
    state.totalItems = payload.totalItems;
  }
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
};
