const state = {
  nodes: []
};

const actions = {};

const getters = {};

const mutations = {
  SET_NODES(state, payload) {
    state.nodes = payload.nodes;
  }
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
};
