/* eslint-disable */
import * as Browser from '@/services/browser';
import { guid } from '@/services/utils';
import { remove } from 'lodash';

export default {
	namespaced: true,

	state: {
		default: {
			id: null,
			content: '',
			data: {},
			fullscreen: false,
			loadComponent: false,
			loading: false,
			textCenter: false,
			title: '',
			visible: true,
			width: '70%'
		},
		instances: []
	},

	mutations: {
		CLOSE_DIALOG: function(state, payload) {
			// If the instance has an id remove dialog with this id
			// othwise remove the last item in array
			if (payload && payload.instance) {
				remove(state.instances, dialog => {
					if (dialog.id === payload.instance.id) {
						dialog.visible = false;
					}
					return dialog.id === payload.instance.id;
				});
			} else {
				state.instances.pop();
			}

			const body = document.querySelector('body');
			body.classList.remove('el-dialog--open');
		},

		OPEN_DIALOG: function(state, payload) {
			let currPayload = Object.assign({}, state.default, payload);

			if (Browser.isDeviceXS() || Browser.isDeviceSM()) {
				currPayload.fullscreen = true;
				currPayload.width = '100%';
			}

			// Get dialog instance with loading state
			// and replace the object with the new one
			let hasModifiedObject = false;
			state.instances.forEach(instance => {
				if (!instance.loading) {
					return;
				}

				hasModifiedObject = true;
				Object.assign(instance, payload);
			});

			if (hasModifiedObject) {
				return;
			}

			// Create new dialog instance and push to instances array
			const dialog = new Dialog(currPayload);
			dialog.id = guid();
			state.instances.push(dialog);

			const body = document.querySelector('body');
			body.classList.add('el-dialog--open');
    },

    SET_LOADING(state, payload) {
      if (!state.instances.length) {
        return;
      }
      // Set loading state for latest opend dialog
      state.instances[state.instances.length - 1].loading = payload;
    }
  }
};

class Dialog {
	constructor(obj) {
		this.id = obj.id;
		this.content = obj.content;
		this.data = obj.data;
		this.fullscreen = obj.fullscreen;
		this.loadComponent = obj.loadComponent;
		this.loading = obj.loading;
		this.textCenter = obj.textCenter;
		this.title = obj.title;
		this.visible = obj.visible;
		this.width = obj.width;
	}
}

export const MODULE_NAME = 'dialog';
