<template>
  <div id="app" class="app" :class="{ 'app--sticky': offcanvasVisible }">
    <AppLoading></AppLoading>

    <router-view></router-view>

    <!-- Shared components -->
    <Dialog></Dialog>
  </div>
</template>

<script>
import { get } from 'lodash';
import { getLanguage } from '@/services/browser';
import AppLoading from '@/components/App/AppLoading';
import Dialog from '@/components/Dialog';
import mixins from '@/shared/mixins/index';

export default {
  name: 'App',

  mixins: [mixins],

  components: {
    AppLoading,
    Dialog
  },

  data() {
    return {
      defaultNode: ''
    };
  },

  computed: {
    offcanvasVisible() {
      return this.$store.state.offcanvas.visible;
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      let language = getLanguage();
      if (this.$localStorage.get('languageCode')) {
        language = this.$localStorage.get('languageCode');
      }

      this.$store.commit('SET_APP_LANGUAGE', {
        language: language
      });

      this.requestLanguage(language);

      // Set body classes
      if (this.isBrowserIE) {
        const bodyClasses = document.querySelector('body').classList;
        bodyClasses.add(this.isBrowserIE);
      }
    },

    requestLanguage(language) {
      this.$store.dispatch('GET_TRANSLATION').then(response => {
        const responseData = get(response, 'data.RESPONSE', []);
        this.$store.commit('SET_LOCAL_MESSAGE', {
          language: language,
          data: responseData
        });
      });
    }
  }
};
</script>

<style lang="scss">
.app {
  &--sticky {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &.is-login {
    .app-content {
      max-width: 100%;
    }
  }
}
</style>
