import store from '@/store/index';
import config from '@/config/index';
import loginAPI from '@/config/api.login';

/**
 * Check if current page is login
 * @param  {router object}   to
 * @return {Boolean}
 */
export function checkIfLoginPage(to) {
  return to.name === 'Login' || to.name === 'Register';
}

/**
 * Handle login page
 * @param  {router object}   to
 * @return {void}
 */
export function handlePageChanges(to) {
  if (to.name === 'Login') {
    store.commit('SET_IS_LOGIN_PAGE', { isLoginPage: true });
    store.commit('SET_CURRENT_VIEW', { currentView: 'Login' });
  } else {
    store.commit('SET_IS_LOGIN_PAGE', { isLoginPage: false });
    store.commit('SET_CURRENT_VIEW', { currentView: '' });
  }
}

/**
 * Check if User is authenticated
 * @return {Boolean}
 */
export function isAuthenticated() {
  return store.getters.IS_AUTHENTICATED;
}

export function authorize(idToken) {
  return loginAPI.get(config.login.authorize, {
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  });
}
