const state = {
  loading: false,
  node: {
    GuiNodeCh: []
  },
  totalItems: 0
};

const actions = {};

const getters = {};

const mutations = {
  SET_NODE(state, payload) {
    state.node = payload.node;
    state.totalItems = Object.keys(payload.node.GuiNodeCh).length;
  }
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
};
