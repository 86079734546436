const state = {
  fullscreen: false,
  loading: false,
  node: {
    GuiNodeCh: []
  },
  totalItems: 0,
  visible: false,
  width: '70%'
};

const actions = {};

const getters = {};

const mutations = {
  CLOSE(state) {
    state.visible = false;
    state.node = { GuiNodeCh: [] };
  },

  OPEN(state, payload) {
    state.node = payload.node || { GuiNodeCh: [] };
    state.fullscreen = payload.fullscreen || false;
    state.width = payload.width || '70%';
    state.loading = payload.loading || false;
    state.visible = payload.visible || false;
  },

  SET_NODE(state, payload) {
    state.node = payload.node;
    state.totalItems = Object.keys(payload.node.GuiNodeCh).length;
  }
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
};
