/* eslint-disable no-unused-vars */
import API from './../../config/api';

const state = {
  loading: false,
  news: {
    GuiNodeCh: []
  },
  node: {
    GuiNodeCh: []
  },
  pagination: {
    currentPage: 1,
    pageOffset: 1,
    pageSize: 20,
    pageSizes: [20, 50, 100]
  },
  totalItems: 0
};

const actions = {
  GET_NEWS({ commit, rootState }, payload) {
    console.log(payload);

    return API.get('/News', {
      params: {
        data: {
          SESSIONNUMBER: rootState.app.sessionNumber,
          PAGELIMIT: payload.PAGELIMIT,
          PAGEOFFSET: payload.PAGEOFFSET,
          SEARCHSTRING: payload.SEARCHSTRING
        }
      }
    })
      .then(response => {
        commit('SET_NEWS', { news: response.data.RESPONSE });
        return response;
      })
      .catch(err => {
        console.log('GET_NEWS error', err);
        return err;
      });
  },

  GET_NEWS_CONTENT({ commit }, payload) {
    return API.get('/NewsTT', {
      params: {
        data: {
          LANQKEY: payload.Value01
        }
      }
    })
      .then(response => {
        return response;
      })
      .catch(err => {
        console.log('GET_NEWS_CONTENT error', err);
        return err;
      });
  },

  GET_NEWS_CURRENT({ rootState }) {
    return API.get('/NewsCurrent', {
      params: {
        data: {
          MENUELANQ: rootState.app.language
        }
      }
    })
      .then(response => {
        return response;
      })
      .catch(err => {
        console.log('GET_NEWS_CURRENT error', err);
        return err;
      });
  }
};

const getters = {};

const mutations = {
  RESET_PAGINATION(state) {
    state.pagination.currentPage = 1;
    state.pagination.pageSize = 20;
  },

  SET_NEWS(state, payload) {
    state.news = payload.news;
  },

  SET_NODE(state, payload) {
    state.node = payload.node;
  },

  SET_PAGINATION(state, payload) {
    state.pagination.currentPage = payload.currentPage;
    state.pagination.pageOffset = payload.pageOffset;
    state.pagination.pageSize = payload.pageSize;
  },

  SET_TOTAL_ITEMS(state, payload) {
    state.totalItems = payload.totalItems;
  }
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
};
