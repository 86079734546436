/* eslint-disable */
import API from '@/config/api';

const state = {
	node: {
		MachinePicPath: ''
	}
};

const actions = {
	GET_MACHINE_INFO: function({ commit, state, rootState }) {
		state.node.MachinePicPath = '';

		return API.get('/MachineInfo', {
			params: {
				data: {
					SESSIONNUMBER: rootState.app.sessionNumber
				}
			}
		})
			.then(response => {
				commit('SET_MACHINE_INFO', response.data.RESPONSE);
				return response;
			})
			.catch(err => {
				return err;
			});
	}
};

const getters = {};

const mutations = {
	SET_MACHINE_INFO: function(state, payload) {
		state.node.ElemetsRs = payload.ElemetsRs;
		state.node.MachinePicPath = payload.MachinePicPath || '';
	}
};

export default {
	namespaced: true,
	actions,
	getters,
	mutations,
	state
};
