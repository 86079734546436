<template>
  <el-dropdown trigger="click" @visible-change="handleVisibleChange" :class="{ 'is-open': isOpen }">
    <el-button type="text" @click="onClickDropdown">
      <span class="fa fa-user-circle-o"></span>
      <span class="dropdown__value m-l-half">{{ user.name }}</span>
      <i class="el-icon-arrow-down el-icon--right"></i>
    </el-button>
    <el-dropdown-menu slot="dropdown" v-loading="loading">
      <el-dropdown-item v-for="item in items" :key="item.Xnodekey">
        <a href="#" @click.prevent="onClickItem(item)">{{ item.Lanqkey01 }}</a>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { get } from 'lodash';
import mixins from '@/shared/mixins/index';

export default {
  name: 'Dropdown',

  mixins: [mixins],

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    user() {
      return this.$store.state.user;
    }
  },

  data() {
    return {
      isOpen: false,
      items: {
        PLACEHOLDER: {
          Lanqkey01: '',
          Xnodekey: 0
        }
      },
      loading: false
    };
  },

  methods: {
    handleItemClick(node) {
      const XcontrolChild = get(node, 'XcontrolChild', '');
      const Xnodekey = get(node, 'Xnodekey', '');

      if (XcontrolChild === 'MNUCHANGEPWD') {
        this.onClickChangePassword();
      } else if (XcontrolChild === 'MNULOGOUT') {
        this.onClickLogout();
      } else if (XcontrolChild === 'MNUCHANGEUSER') {
        this.onClickChangeUser();
      } else {
        this.$store.commit('pages/SET_PAGES_LOADING', { loading: true, nodeKey: Xnodekey });

        this.requestNode(Xnodekey).then(result => {
          const RESPONSE = get(result, 'data.RESPONSE');
          this.$store.commit('pages/SET_PAGES_LOADING', { loading: false });
          if (RESPONSE) {
            this.handleNode(RESPONSE);
          }
        });
      }
      this.$store.commit('CLOSE_OFFCANVAS');
    },

    handleVisibleChange(value) {
      this.isOpen = value;
    },

    onClickChangePassword() {
      this.$store.commit('dialog/OPEN_DIALOG', {
        loadComponent: 'FormChangePassword',
        title: this.$t('CHANGE_PASSWORD'),
        width: '50%'
      });
    },

    onClickChangeUser() {
      this.$store.commit('dialog/OPEN_DIALOG', {
        loadComponent: 'FormChangeUser',
        title: this.$t('CHANGE_USER'),
        width: '50%'
      });
    },

    onClickDropdown() {
      const Xnodekey = get(this.data, 'Xnodekey', '');
      console.log('onClickDropdown', Xnodekey);
      this.loading = true;

      this.requestNode(Xnodekey)
        .then(result => {
          const GuiNodeCh = get(result, 'data.RESPONSE.GuiNodeCh', {});
          this.items = Object.assign({}, GuiNodeCh);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onClickItem(node) {
      this.handleItemClick(node);
    },

    onClickLogout() {
      this.$router.push({ path: '/logout' });
    }
  }
};
</script>
