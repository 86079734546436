<template>
  <nav class="app-navigation">
    <div class="container">
      <div class="app-navigation__btn-container">
        <div class="app-navigation__logo">
          <router-link :to="{ path: '/' }" class="flex">
            <SVGLogo />
          </router-link>
        </div>

        <div class="app-navigation__name">M-iPlan</div>

        <div class="flex a-center">
          <el-button type="primary" class="btn-offcanvas" @click.prevent="toggleOffcanvas">
            <div class="nav-icon nav-icon--light" :class="{ 'nav-icon--cross': offcanvasVisible }">
              <span class="nav-icon__stripe"></span>
              <span class="nav-icon__stripe"></span>
            </div>
          </el-button>
          <span class="m-l-small m-l-1--md color-white">Menü</span>
        </div>
      </div>

      <div class="hide-sm">
        <NavItems :data="data" :activeNode="activeNode" />
      </div>
    </div>
  </nav>
</template>

<script>
import mixins from '@/shared/mixins/index';
import NavItems from '@/components/Navigation/NavItems';
import SVGLogo from '@/assets/img/logo-meiko.svg';

export default {
  name: 'AppNavigation',

  mixins: [mixins],

  components: {
    NavItems: NavItems,
    SVGLogo
  },

  data() {
    return {
      node: {}
    };
  },

  computed: {
    activeNode() {
      return this.$store.state.pages.nodeMain;
    },

    data() {
      return this.$store.state.webribbon.node;
    },

    imageLoaderPath() {
      return this.$store.state.app.imageLoaderPath;
    },

    offcanvasVisible() {
      return this.$store.state.offcanvas.visible;
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {},

    toggleOffcanvas() {
      this.$store.commit('TOGGLE_OFFCANVAS');
    }
  }
};
</script>

<style lang="scss">
.app-navigation {
  background-color: $c-brand;
  border-top: 1px solid $c-light-gray;
  padding: 0 0.5rem;

  @media screen and (min-width: $screen-lg) {
    padding-right: 0;
    padding-left: 0;
  }

  &__btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $distance-md;

    @media screen and (min-width: $screen-md) {
      display: none;
    }
  }

  .app-navigation__logo {
    display: flex;
    margin-right: 0;
    text-align: center;

    svg {
      max-height: 3rem;
      overflow: visible;
      width: 4rem;

      g {
        display: none;
      }
    }
  }

  .app-navigation__name {
    color: #ffffff;
    display: none;
    font-family: $font-brand;
    font-size: 1.4rem;

    @media screen and (min-width: 360px) {
      display: flex;
      flex: 1 1 auto;
      justify-content: center;
    }
  }
}
</style>
