<template>
  <div class="app-breadcrumb">
    <div class="container">
      <el-breadcrumb separator-class="el-icon-d-arrow-right">
        <el-breadcrumb-item v-for="node in nodes" :key="node.Xnodekey" :to="node.Xnodekey">{{
          node.Lanqkey01 | stripHtml
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
import stripHtml from '@/shared/filters/striphtml.filter';

export default {
  name: 'AppBreadcrumb',

  filters: {
    stripHtml
  },

  computed: {
    nodes() {
      return this.$store.state.breadcrumb.nodes;
    }
  }
};
</script>

<style lang="scss">
.app-breadcrumb {
  background-color: $c-medium-gray;
  border-bottom: 1px solid $c-extra-light-gray;
  padding: 0.7rem 1rem;
  min-height: 2rem;

  @media screen and (min-width: $screen-lg) {
    padding-right: 0;
    padding-left: 0;
  }

  .el-breadcrumb__item {
    line-height: 1.3rem;
  }
}
</style>
