<template>
  <div class="offcanvas offcanvas--left offcanvas--brand" :class="{ 'is-visible': visible }">
    <div class="p-l-1 p-r-1 m-b-1 m-t-half pos-r">
      <div class="app-header__logo">
        <SVGLogo />
      </div>

      <div class="app-header__headline" v-t="'MIPLAN_PRODUCTCONFIGURATOR'"></div>

      <!-- Toogle Button -->
      <el-button type="text" class="hide-md btn-offcanvas" @click.prevent="toggleOffcanvas">
        <div class="nav-icon nav-icon--light nav-icon--cross">
          <span class="nav-icon__stripe"></span>
          <span class="nav-icon__stripe"></span>
        </div>
      </el-button>
    </div>

    <NavItems :data="data" :activeNode="activeNode" />

    <hr class="offcanvas__separator" />

    <AppNavigationSecondary />
  </div>
</template>

<script>
import AppNavigationSecondary from '@/components/App/AppNavigationSecondary';
import NavItems from '@/components/Navigation/NavItems';
import SVGLogo from '@/assets/img/logo-meiko.svg';

export default {
  name: 'AppOffCanvas',

  components: {
    AppNavigationSecondary,
    NavItems,
    SVGLogo
  },

  computed: {
    activeNode() {
      return this.$store.state.pages.nodeMain;
    },

    data() {
      return this.$store.state.webribbon.node;
    },

    imageLoaderPath() {
      return this.$store.state.app.imageLoaderPath;
    },

    visible() {
      return this.$store.state.offcanvas.visible;
    }
  },

  methods: {
    toggleOffcanvas() {
      this.$store.commit('CLOSE_OFFCANVAS');
    }
  }
};
</script>

<style lang="scss">
.offcanvas {
  background-color: #ffffff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.8);

  transition: all 0.3s ease-in-out;

  position: fixed;
  top: 0;

  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 2001;

  @media screen and (min-width: 520px) {
    width: 80%;
  }

  @media screen and (min-width: $screen-sm) {
    width: 70%;
  }

  &--left {
    left: 0;
    transform: translate3d(-105%, 0, 0);
  }

  &--right {
    right: 0;
    transform: translate3d(105%, 0, 0);
  }

  &--brand {
    background-color: $c-brand;
  }

  &.is-visible {
    transform: translate3d(0, 0, 0);
  }

  &__separator {
    margin: 2rem 0;
    height: 1px;
    border: 0;
  }

  /**
	 * Extra modifications for NAV LINKS
	 */
  .nav__link {
    padding-top: 0;
    padding-bottom: 0;

    &:hover,
    &.is-active--exact,
    &.is-active--trail {
      background-color: transparent !important;

      .nav__link-name {
        &:before {
          content: '';
          width: calc(100% + 53px);
        }
      }
    }
  }

  .nav__link-name {
    position: relative;
    overflow: visible;

    padding: 1.5rem 0;
    font-size: 1.1rem;

    &:before {
      content: '';
      position: absolute;
      display: block;
      left: -51px;
      bottom: 0;
      height: 1px;
      background-color: #ffffff;
      width: 0;

      transition: width 0.3s ease-in-out;
    }
  }

  .app-header__logo {
    margin-right: 0;
    text-align: center;

    svg {
      max-height: 3rem;

      g {
        display: none;
      }
    }
  }

  .app-header__headline {
    color: #ffffff;
    font-family: $font-brand;
    font-size: 0.9rem;
    text-align: center;
  }

  .btn-offcanvas {
    position: absolute;
    right: 0;
    top: 0;
  }

  // Dropdown
  .el-dropdown {
    .el-button {
      color: #ffffff;
      display: block;
      font-size: 1.1rem;
      padding-right: 1.2rem;
      padding-left: 1.2rem;

      > span {
        display: flex;
        align-items: center;
      }

      .fa {
        font-size: 2rem;
        display: inline-block;
        margin-right: 0.625rem;
      }
    }

    .dropdown__value {
      display: block;
      padding: 1.5rem 0;
    }
  }
}
</style>
