import API from '@/config/api';

const NAME = 'HeatFlowData';

export function calculate(payload) {
  return API.post(`${NAME}/calculate`, payload);
}

export function getMachines(payload) {
  const { sessionNumber } = payload;
  return API.get(`${NAME}/machines`, {
    params: {
      data: {
        SESSIONNUMBER: sessionNumber
      }
    }
  });
}

export function getMachinesGenerated(payload) {
  const { sessionNumber } = payload;
  return API.get(`${NAME}/machines/generated`, {
    params: {
      data: {
        SESSIONNUMBER: sessionNumber
      }
    }
  });
}

export function getDishes(payload) {
  const { sessionNumber } = payload;
  return API.get(`${NAME}/known-dishes`, {
    params: {
      data: {
        SESSIONNUMBER: sessionNumber
      }
    }
  });
}

export function getDishMaterials(payload) {
  const { sessionNumber } = payload;
  return API.get(`${NAME}/known-dishes/materials`, {
    params: {
      data: {
        SESSIONNUMBER: sessionNumber
      }
    }
  });
}

export function getDishCategories(payload) {
  const { sessionNumber } = payload;
  return API.get(`${NAME}/known-dishes/categories`, {
    params: {
      data: {
        SESSIONNUMBER: sessionNumber
      }
    }
  });
}

export function getDishImage(payload) {
  const { id, sessionNumber } = payload;
  return API.get(`${NAME}/known-dishes/${id}/image`, {
    params: {
      data: {
        SESSIONNUMBER: sessionNumber
      }
    }
  });
}
