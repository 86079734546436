// German translation
const de = {
  BROWSER_REQUIREMENTS: 'Browseranforderungen',
  CANCEL: 'Abbrechen',
  COMPANY: 'Firma',
  COUNTRY: 'Land',
  DISCLAIMER: 'Haftungsausschluss',
  EMAIL: 'E-Mail',
  FIRSTNAME_LASTNAME: 'Vor, Nachname',
  FORGOT_PASSWORD: 'Passwort vergessen',
  IMPRINT: 'Impressum',
  LOADING: 'Laden...',
  LOGIN: 'Anmelden',
  MEIKO_ADDRESS:
    'MEIKO Maschinenbau GmbH & Co. KG, Englerstr. 3 - D-77652 Offenburg Telefon: +49(0)781 203 - 0, Fax: +49(0)781 203 - 1179, E-Mail: info@meiko.de',
  MIPLAN_PRODUCTCONFIGURATOR: 'M-iPlan Produktkonfigurator',
  PASSWORD: 'Passwort',
  PLEASE_ENTER_VALID_EMAIL: 'Bitte geben Sie eine valide E-Mail-Adresse ein',
  PLEASE_FILL_OUT_MANDATORY_FIELDS:
    'Bitte beachten Sie: Die mit * gekennzeichneten Felder sind Pflichtfelder und müssen ausgefüllt werden.',
  PLZ_CITY: 'Plz, Stadt',
  PRIVACY_POLICY: 'Datenschutz',
  PHONE: 'Telefon',
  PHONE2: 'Vorwahl',
  PHONE3: 'Durchwahl',
  REGISTER: 'Registrieren',
  REPEAT_PASSWORD: 'Passwort wiederholen',
  RESET: 'Zurücksetzen',
  SESSION_NUMBER: 'Session-Nr.',
  STREET: 'Straße',
  TEXT_MANDATORY_FIELDS: '',
  TEXT_PRIVACY_POLICY_CHECKBOX: 'Ich habe die Datenschutzerklärung gelesen und akzeptiere diese.',
  WEB_VERSION: 'Web-Version'
};

export default de;
