import Vue from 'vue';
import axios from 'axios';
import config from './index';
import { get } from 'lodash';

const api = axios.create({
  baseURL: `${config.login.host}`,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
});

/*
 * Interceptor to handle unauthorized requests
 */
api.interceptors.response.use(
  response => {
    // All fine
    return response;
  },
  error => {
    console.log('interceptors', error);

    // Display message
    Vue.prototype.$notify({
      type: 'error',
      title: get(error, 'response.statusText', ''),
      message: get(error, 'response.statusText', ''),
      duration: 8000,
      showClose: true
    });

    return Promise.reject(error);
  }
);

export default api;
