/* eslint-disable no-unused-vars */
import API from './../../config/api';

const state = {
  childComponentData: {},
  loading: false,
  node: {
    GuiNodeCh: []
  },
  totalItems: 0
};

const actions = {
  GET_MACHINE_ELEMENTS({ commit, state, rootState }, payload) {
    const nodeKey = payload.nodeKey;
    const nodeInput = payload.nodeInput;

    return API.get('/GetMachineElements', {
      params: {
        data: {
          SESSIONNUMBER: rootState.app.sessionNumber,
          NODENUMBER: nodeKey,
          NODEINPUT: nodeInput
        }
      }
    })
      .then(response => {
        commit('SET_NODE', { node: response.data.RESPONSE });
        return response;
      })
      .catch(err => {
        return err;
      });
  },

  SET_MACHINE_ELEMENT({ commit, state, rootState }, payload) {
    const nodeKey = payload.nodeKey;
    const nodeInput = payload.nodeInput;

    return API.post(
      'MachineElement' + `?data={"SESSIONNUMBER":"${rootState.app.sessionNumber} ","NODENUMBER":"${nodeKey}","NODEINPUT":"${nodeInput}"}`
    )
      .then(response => {
        return response;
      })
      .catch(err => {
        return err;
      });
  }
};

const getters = {};

const mutations = {
  SET_CHILD_COMPONENT_DATA(state, payload) {
    state.childComponentData = payload;
  },

  SET_NODE(state, payload) {
    state.node = payload.node;
    state.totalItems = Object.keys(payload.node.GuiNodeCh).length;
  }
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
};
