import AppLayoutComponent from '@/layout/AppLayout';
import DefaultLayoutComponent from '@/layout/DefaultLayout';

const Login = () => import('@/views/Login');
const Logout = () => import('@/views/Logout');
const Pages = () => import('@/views/Pages');
const NotFound = () => import('@/views/404');

export default [
  {
    path: '/login',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        name: 'Login',
        component: Login
      }
    ]
  },
  {
    path: '/logout',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        name: 'Logout',
        component: Logout
      }
    ]
  },
  {
    path: '/',
    redirect: '/pages'
  },
  {
    path: '/pages',
    components: {
      default: AppLayoutComponent
    },
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        components: {
          default: Pages
        },
        meta: { requiresAuth: true }
      },
      {
        path: '/pages/:id',
        name: 'Pages',
        components: {
          default: Pages
        },
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '*',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: NotFound,
        meta: {
          requiresAuth: false
        }
      }
    ]
  }
];
