<template>
  <ul class="nav__items">
    <NavItem v-for="item in data.GuiNodeCh" :key="item.Xnodekey" :data="item" :showIcon="showIcons" :activeNode="activeNode"></NavItem>
  </ul>
</template>

<script>
import NavItem from './NavItem';

export default {
  name: 'NavItems',

  components: {
    NavItem: NavItem
  },

  props: {
    activeNode: {
      type: Object
    },

    data: {
      type: Object,
      required: true
    },

    showIcons: {
      type: Boolean,
      default: true,
      required: false
    }
  }
};
</script>
