/* eslint-disable no-unused-vars */
import API from './../../config/api';

const state = {
  loading: false,
  node: []
};

const getters = {
  MY_JOBS: (state, getters, rootState) => {
    return state.node.filter(item => item.XUSER === rootState.user.userlogin);
  }
};

const mutations = {
  SET_NODE(state, payload) {
    state.node = payload.node;

    state.node.forEach((item, index) => {
      item.id = index;
    });
  }
};

const actions = {
  GET_JOBS({ commit, rootState }) {
    return API.get('/BatchSessions', {
      params: {
        data: {
          SESSIONNUMBER: rootState.app.sessionNumber
        }
      }
    })
      .then(response => {
        commit('SET_NODE', { node: response.data.RESPONSE });
        return response;
      })
      .catch(err => {
        return err;
      });
  }
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
};
