<template>
  <el-form :model="form" :rules="rules" ref="formPrivacyPolicy">
    <el-form-item prop="HASREADPRIVACYMIP">
      <el-checkbox v-model="form.HASREADPRIVACYMIP">
        <span v-html="$t('TEXT_PRIVACY_POLICY_CHECKBOX')"></span>
      </el-checkbox>
    </el-form-item>

    <el-form-item class="text-right m-b-0">
      <el-button type="text" @click="onClose" class="m-r-1">{{ $t('CANCEL') }}</el-button>
      <el-button type="primary" @click="onSubmitForm('formPrivacyPolicy')">{{ $t('ACCEPT') }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'FormPrivacyPolicy',

  data() {
    return {
      form: {
        HASREADPRIVACYMIP: null
      },
      rules: {
        HASREADPRIVACYMIP: [{ required: true, message: this.$t('PLEASE_FILL_OUT_MANDATORY_FIELDS'), trigger: 'change' }]
      }
    };
  },

  methods: {
    onClose() {
      this.$store.commit('dialog/CLOSE_DIALOG');
    },

    onSubmitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid && this.form.HASREADPRIVACYMIP) {
          this.$store.dispatch('SET_PRIVACY_POLICY', { HASREADPRIVACYMIP: this.form.HASREADPRIVACYMIP }).then(response => {
            if (response.data.MESSAGE.toUpperCase() === 'OK') {
              this.onClose();

              this.$message({
                type: 'success',
                message: this.$t('THANK_YOU'),
                duration: 7000,
                showClose: true
              });
            }
          });
        } else {
          this.form.HASREADPRIVACYMIP = null;
          return false;
        }
      });
    }
  }
};
</script>
