<template>
  <nav class="app-navigation--secondary">
    <NavItems :data="data" :showIcons="false" />
  </nav>
</template>

<script>
import NavItems from '@/components/Navigation/NavItems';

export default {
  name: 'AppNavigationSecondary',

  components: {
    NavItems
  },

  computed: {
    data() {
      return this.$store.state.webribbon.info;
    },

    offcanvasVisible() {
      return this.$store.state.offcanvas.visible;
    }
  }
};
</script>

<style lang="scss">
.app-navigation--secondary {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  .nav__items {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (min-width: $screen-md) {
      flex-direction: row;
    }
  }

  .nav__item {
    @media screen and (min-width: $screen-md) {
      border-bottom: none;
      margin-left: 2rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .nav__link {
    min-width: 0;
    flex-direction: row;

    @media screen and (min-width: $screen-md) {
      padding: 0;

      &:hover,
      &.is-active,
      &.is-active--exact {
        background-color: transparent !important;

        .nav__link-icon,
        .nav__link-name {
          color: $c-brand;
        }
      }

      &.is-active,
      &.is-active--exact {
        .nav__link-icon,
        .nav__link-name {
          color: $c-brand;
        }
      }
    }
  }

  .nav__link-name {
    @media screen and (min-width: $screen-md) {
      color: $c-black;
      font-size: 1rem;
      transition: all 0.2s ease-in-out;
    }
  }

  .nav__link-icon {
    margin-right: 0.625rem;
    margin-bottom: 0;

    @media screen and (min-width: $screen-md) {
      color: $c-brand;
      font-size: 14px;
      margin-right: 0.3rem;
    }
  }

  .nav__link-marker {
    display: none;
  }

  .el-button {
    padding: 0;
  }

  @media screen and (min-width: $screen-md) {
    .el-dropdown {
      &.is-open {
        .el-button,
        .dropdown__value {
          color: $c-brand;
        }
      }
    }

    .el-button,
    .dropdown__value {
      color: $c-black;
      transition: all 0.2s ease-in-out;
    }

    .el-button {
      &:hover {
        color: $c-brand;

        .dropdown__value {
          color: $c-brand;
        }
      }
    }
  }
}
</style>
