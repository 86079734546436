import { get, sortBy } from 'lodash';
import * as HeatFlowDataService from '@/services/heatFlowData';
import { transformForSelect } from '@/services/utils';

const state = {
  isLoadingDishes: false,
  isLoadingMachines: false,
  categories: [],
  dishes: [],
  machines: [],
  machinesConfigured: [],
  materials: [],
  node: {
    GuiNodeCh: []
  }
};

const getters = {
  allDishCategories: state => state.categories,
  allDishMaterials: state => {
    return transformForSelect(state.materials, { label: 'DisplayName', value: 'Id' });
  },
  allDishes: state => state.dishes,
  allMachines: state => {
    return transformForSelect(state.machines, { label: 'DisplayName', value: 'NodeId' });
  },
  dishById: state => id => {
    return state.dishes.find(item => {
      return item.Id === id;
    });
  },
  dishesGroupByCategory: state => {
    if (!state.categories.length || !state.dishes.length) {
      return [];
    }

    const categories = state.categories.slice();
    categories.map(category => {
      const categoryId = parseInt(get(category, 'Id'));
      if (categoryId) {
        category.dishes = state.dishes.filter(dish => dish.CategoryId === categoryId);
      }
    });

    return categories;
  },
  dishMaterialById: state => id => {
    return state.materials.find(item => {
      return item.Id === id;
    });
  },
  isLoadingDishes: state => state.isLoadingDishes,
  isLoadingMachines: state => state.isLoadingMachines,
  machineById: state => id => {
    return state.machines.find(item => {
      return item.NodeId === id;
    });
  },
  machineConfiguredById: state => id => {
    return state.machinesConfigured.find(item => {
      const { NodeId, SessionNumber } = item;
      return `${NodeId}+${SessionNumber}` === id;
    });
  },
  machinesGroupByModule: state => {
    const groups = [];

    // Extract the modules from the machines
    if (state.machines.length) {
      state.machines.forEach(machine => {
        if (!groups.includes(machine.ModuleName)) {
          groups.push(machine.ModuleName);
        }
      });
    }

    // Build  new array with machines beeing grouped by module
    const machineGroups = [];
    if (groups.length) {
      groups.forEach(groupName => {
        const machines = state.machines.filter(machine => {
          return machine.ModuleName === groupName;
        });
        const obj = {
          label: groupName,
          machines
        };
        machineGroups.push(obj);
      });
    }

    return sortBy(machineGroups, ['label']);
  },
  machinesConfiguredGroupByModule: state => {
    const groups = [];

    // Extract the modules from the machines
    if (state.machinesConfigured.length) {
      state.machinesConfigured.forEach(machine => {
        const ModuleName = get(machine, 'MachineHeatData.ModuleName', '');
        if (!groups.includes(ModuleName)) {
          groups.push(ModuleName);
        }
      });
    }

    // Build  new array with machines beeing grouped by module
    const machineGroups = [];
    if (groups.length) {
      groups.forEach(groupName => {
        const machines = state.machinesConfigured.filter(machine => {
          const ModuleName = get(machine, 'MachineHeatData.ModuleName', '');
          return ModuleName === groupName;
        });
        const obj = {
          label: groupName,
          machines
        };
        machineGroups.push(obj);
      });
    }

    return sortBy(machineGroups, ['label']);
  },
  node: state => state.node
};

const mutations = {
  SET_LOADING_DISHES(state, payload) {
    state.isLoadingDishes = payload;
  },

  SET_LOADING_MACHINES(state, payload) {
    state.isLoadingMachines = payload;
  },

  SET_NODE(state, payload) {
    state.node = get(payload, 'node');
    const GuiNodeCh = get(state.noe, 'GuiNodeCh');
    if (GuiNodeCh) {
      state.totalItems = Object.keys(GuiNodeCh).length;
    }
  },

  SET_DISH_CATEGORIES(state, payload) {
    state.categories = payload.slice();
  },

  SET_DISHES(state, payload) {
    state.dishes = payload.slice();
  },

  SET_MACHINES(state, payload) {
    state.machines = payload.slice();
  },

  SET_MACHINES_CONFIGURED(state, payload) {
    const machines = payload.slice();
    machines.map(machine => {
      machine.DisplayName = get(machine, 'MachineHeatData.DisplayName', '');
      machine.HeatFlow = get(machine, 'MachineHeatData.HeatFlow', {});
      machine.ModuleName = get(machine, 'MachineHeatData.ModuleName', '');
      machine.NodeId = get(machine, 'MachineHeatData.NodeId', '');
      machine.PicturePath = get(machine, 'MachineHeatData.PicturePath', '');
      machine.SubModuleName = get(machine, 'MachineHeatData.SubModuleName', '');
      machine.label = `${machine.DisplayName}`;

      // Add description if availabale
      if (machine.Description.length) {
        machine.label = `${machine.DisplayName} (${machine.Description})`;
      }

      // Add session number if availabale
      const SessionNumber = get(machine, 'SessionNumber');
      if (SessionNumber) {
        machine.label = `${machine.label} (${SessionNumber})`;
      }
      // Add SessionNumber to get a unique identifier because the NodeId is not sufficent for preconfigured machines
      machine.value = `${machine.NodeId}+${machine.SessionNumber}`;
    });
    state.machinesConfigured = machines;
  },

  SET_DISH_MATERIALS(state, payload) {
    state.materials = payload.slice();
  }
};

const actions = {
  async GET_DISHES({ commit, rootGetters }) {
    const payload = {
      sessionNumber: rootGetters['SESSION_NUMBER']
    };

    try {
      commit('SET_LOADING_DISHES', true);
      const response = await HeatFlowDataService.getDishes(payload);
      const data = get(response, 'data');
      if (data) {
        commit('SET_DISHES', data);
      }
    } catch (err) {
      throw new Error(err);
    } finally {
      commit('SET_LOADING_DISHES', false);
    }
  },

  async GET_DISH_CATEGORIES({ commit, rootGetters }) {
    const payload = {
      sessionNumber: rootGetters['SESSION_NUMBER']
    };

    try {
      // commit('SET_LOADING_MACHINES', true);
      const response = await HeatFlowDataService.getDishCategories(payload);
      const data = get(response, 'data');
      if (data) {
        commit('SET_DISH_CATEGORIES', data);
      }
    } catch (err) {
      throw new Error(err);
    } finally {
      // commit('SET_LOADING_MACHINES', false);
    }
  },

  async GET_DISH_MATERIALS({ commit, rootGetters }) {
    const payload = {
      sessionNumber: rootGetters['SESSION_NUMBER']
    };

    try {
      // commit('SET_LOADING_MACHINES', true);
      const response = await HeatFlowDataService.getDishMaterials(payload);
      const data = get(response, 'data');
      if (data) {
        commit('SET_DISH_MATERIALS', data);
      }
    } catch (err) {
      throw new Error(err);
    } finally {
      // commit('SET_LOADING_MACHINES', false);
    }
  },

  async GET_MACHINES({ commit, rootGetters }) {
    const payload = {
      sessionNumber: rootGetters['SESSION_NUMBER']
    };

    try {
      commit('SET_LOADING_MACHINES', true);
      const response = await HeatFlowDataService.getMachinesGenerated(payload);
      const data = get(response, 'data');
      if (data) {
        commit('SET_MACHINES', data);
      }
    } catch (err) {
      throw new Error(err);
    } finally {
      commit('SET_LOADING_MACHINES', false);
    }
  },

  async GET_MACHINES_CONFIGURED({ commit, rootGetters }) {
    const payload = {
      sessionNumber: rootGetters['SESSION_NUMBER']
    };

    try {
      // commit('SET_LOADING_MACHINES', true);
      const response = await HeatFlowDataService.getMachines(payload);
      const data = get(response, 'data');
      if (data) {
        commit('SET_MACHINES_CONFIGURED', data);
      }
    } catch (err) {
      throw new Error(err);
    } finally {
      // commit('SET_LOADING_MACHINES', false);
    }
  }
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
};

export const MODULE_NAME = 'webroomheatcalc';
