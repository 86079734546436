/* eslint-disable */
import * as Browser from '@/services/browser';

const state = {
	loading: false,
	node: {
		GuiNodeCh: [],
		XcontrolChild: 'WEBDOWNLOADJOBS'
	},
	visible: false,
	width: '30%'
};

const actions = {};

const getters = {};

const mutations = {
	CLOSE: function(state, payload) {
		state.visible = false;
		state.node = { GuiNodeCh: [] };

		const body = document.querySelector('body');
		body.classList.remove('o-hidden');
	},

	OPEN: function(state, payload) {
		if (Browser.isDeviceXS() || Browser.isDeviceSM()) {
			state.width = '100%';
		} else {
			state.width = payload.width || '30%';
		}

		state.node = payload.node || { GuiNodeCh: [] };
		state.loading = payload.loading || false;
		state.visible = payload.visible || false;

		const body = document.querySelector('body');
		body.classList.add('o-hidden');
	},

	SET_NODE: function(state, payload) {
		state.node = payload.node;
	}
};

export default {
	namespaced: true,
	actions,
	getters,
	mutations,
	state
};
