<template>
  <div>
    <el-form :model="form" :rules="rules" ref="form" label-width="160px" :label-position="labelPosition" v-if="!isEnvAzure">
      <el-form-item :label="$t('PASSWORD')" prop="password">
        <el-input type="password" v-model="form.password" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item :label="$t('REPEAT_PASSWORD')" prop="password2">
        <el-input type="password" v-model="form.password2" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item class="text-right">
        <el-button type="text" @click="resetForm('form')" class="m-r-1">{{ $t('RESET') }}</el-button>
        <el-button type="primary" @click="submitForm('form')">{{ $t('CHANGE_PASSWORD') }}</el-button>
      </el-form-item>
    </el-form>
    <div v-else class="text-center">
      <a class="el-button el-button--primary" :href="resetPwdUrl">
        {{ $t('CHANGE_PASSWORD') }}
      </a>
    </div>

    <el-alert
      :title="$t('HINT')"
      type="info"
      :description="$t('HINT_CHANGE_PASSWORD')"
      show-icon
      :closable="false"
      class="m-t-3"
    ></el-alert>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import * as Browser from '@/services/browser';

export default {
  name: 'FormChangePassword',

  computed: {
    ...mapGetters({
      isEnvAzure: 'IS_IDENTITY_AZURE'
    }),

    resetPwdUrl() {
      const host = get(window, 'app.login.host');
      const path = get(window, 'app.login.pwdReset');
      return `${host}${path}`;
    }
  },

  data() {
    // Validation for password field
    var validatePassword2 = (rule, value, callback) => {
      if (this.form.password) {
        if (value !== this.form.password) {
          callback(new Error(this.$t('PASSWORDS_DO_NOT_MATCH')));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    return {
      form: {
        password: '',
        password2: ''
      },
      rules: {
        password: [{ required: true, min: 8, message: this.$t('PASSWORD_TOO_SHORT'), trigger: 'blur' }],
        password2: [{ required: true, validator: validatePassword2, trigger: 'blur' }]
      },
      labelPosition: 'top'
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      if (Browser.isDeviceMD() || Browser.isDeviceLG() || Browser.isDeviceXL()) {
        this.labelPosition = 'left';
      }
    },

    resetForm(name) {
      this.$refs[name].resetFields();
    },

    submitForm(name) {
      this.$refs[name].validate(valid => {
        if (valid) {
          this.$store.dispatch('CHANGE_PASSWORD', this.form).then(response => {
            this.$notify({
              type: 'success',
              title: this.$t('SUCCESS'),
              message: response.data.MESSAGE,
              duration: 6000,
              showClose: true
            });

            setTimeout(() => {
              this.$router.push({ path: '/logout' });
            }, 1000);
          });
        }
      });
    }
  }
};
</script>

<style lang="scss"></style>
