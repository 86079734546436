<!--
	@component:	NodeControls
	@desc:		This component is used to wrap all dynamic components that can be used in the meiko service tool.
				Components will be load automatically if they are required.
				Additionaly, it is neccessary to define props for all components here.
	@author: 	Yannick Herzog, info@hit-services.net
	@modified:	2018-06-30
 -->
<template>
  <component
    v-if="loadComponent && data.AttNotValid === '0'"
    :is="loadComponent"
    :button-state="buttonState"
    :data="data"
    :root-data="rootData"
    :update-node-key="updateNodeKey"
    @button-state="onChangeButtonState"
    @click-item="onClickItem(data)"
  ></component>
</template>

<script>
/* eslint-disable vue/no-async-in-computed-properties */

// import BUTTON from '@/components/Anzcontrols/BUTTON';
import mixins from '@/shared/mixins/index';

export default {
  name: 'NodeControls',

  mixins: [mixins],

  props: {
    buttonState: {
      type: Object,
      required: false,
      default: () => {
        return {
          disabled: false
        };
      }
    },

    component: {
      type: String,
      required: true
    },

    data: {
      type: Object,
      required: true
    },

    rootData: {
      type: Object,
      required: false,
      default: null
    },

    updateNodeKey: {
      type: String,
      required: true
    }
  },

  computed: {
    loader() {
      if (!this.component) {
        return new Promise();
      }
      return () => import(`@/components/Anzcontrols/${this.component}`);
    }
  },

  data() {
    return {
      loadComponent: null
    };
  },

  mounted() {
    this.loader()
      .then(() => {
        this.loadComponent = () => this.loader();
      })
      .catch(() => {
        this.loadComponent = null; //() => import('./ANZTEXT')
      });
  },

  methods: {
    onClickItem(node) {
      // Send event to parent
      this.$emit('click-item', node);
    },

    onChangeButtonState(state) {
      // Send event to parent
      this.$emit('button-state', state);
    }
  }
};
</script>
