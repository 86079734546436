/* eslint-disable no-unused-vars */
import { get } from 'lodash';
import { transformForTree } from '@/services/utils';
import API from './../../config/api';

const state = {
  loading: false,
  filter: {
    query: ''
  },
  node: {
    GuiNodeCh: []
  },
  tree: []
};

const getters = {
  isLoading: state => state.loading,

  tree: state => {
    let tree = state.tree.slice();
    const filterText = state.filter.query;
    const filterTextToLower = filterText.toLowerCase();

    // Include filter
    if (tree.length && filterTextToLower.length) {
      tree = state.tree.filter(item => {
        const Filename = get(item, 'Filename', '');
        const DescriptionLanq = get(item, 'DescriptionLanq');
        return Filename.toLowerCase().includes(filterTextToLower) || DescriptionLanq.toLowerCase().includes(filterTextToLower);
      });
    }

    let result = tree;
    if (filterTextToLower.length < 1) {
      result = transformForTree(tree);
    }

    return result;
  }
};

const mutations = {
  SET_FILTER(state, payload) {
    state.filter.query = get(payload, 'query', '');
  },

  SET_NODE(state, payload) {
    state.node = payload.node;
  },

  SET_TREE(state, payload) {
    state.tree = payload.slice();
  },

  TOGGLE_LOADING(state, payload) {
    state.loading = payload;
  }
};

const actions = {
  async FETCH_TREE({ commit, state }) {
    const MENUELANQ = get(state, 'node.MenueLanguage', 'DE');
    const SESSIONNUMBER = get(state, 'node.Sessionnumber', '');
    const NODENUMBER = get(state, 'node.XSesNodekey', '');
    commit('TOGGLE_LOADING', true);

    try {
      const response = await API.get('/Kdtree', {
        params: {
          data: {
            MENUELANQ,
            SESSIONNUMBER,
            NODENUMBER
          }
        }
      });

      const responseData = get(response, 'data.RESPONSE', []);
      commit('SET_TREE', responseData);
      return response;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit('TOGGLE_LOADING', false);
    }
  }
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
};
