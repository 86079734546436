import Vue from 'vue';
import axios from 'axios';
import config from './index';
import router from './../router/index';

const api = axios.create({
  baseURL: config.api.isHttps ? 'https://' + config.api.host + config.api.path : 'http://' + config.api.host + config.api.path,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
});

/*
 * Interceptor to handle unauthorized requests
 */
api.interceptors.response.use(
  response => {
    // All fine
    return response;
  },
  error => {
    // Vue.prototype.$Progress.fail();
    console.log('interceptors', error);

    // Redirect to login if authentication is required
    if (error.response.status === 401 && !error.config.url.includes('Login')) {
      console.log('status', error);

      Vue.prototype.$notify({
        type: 'error',
        title: error.response.statusText,
        message: error.response.data.MESSAGE,
        duration: 8000,
        showClose: true
      });

      // Redirect to login
      router.push({ name: 'Login' });

      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

Vue.prototype.$http = api;

export default api;
