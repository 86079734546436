import { forEach, get } from 'lodash';

/**
 * Search for a given string in an array with objects. Used to find a string
 * Example: Used to find a string in an table with accountants or clients
 *
 * @param {String} searchString
 * @param {array} searchInArray
 * @param {array} searchInColumns
 * @returns array
 */
export function searchStringInArray(searchString, searchInArray, searchInColumns) {
  const filteredData = searchInArray.filter(row => {
    let match = false;

    forEach(searchInColumns, column => {
      if (!row[column]) {
        return;
      }

      let stringIncluded = false;
      const currentValue = row[column].toString();
      const lowercaseValue = currentValue.toLowerCase();
      stringIncluded = lowercaseValue.includes(searchString);

      if (stringIncluded) {
        match = true;
      }
    });

    return match ? row : false;
  });

  return filteredData;
}

/**
 * Get object size
 *
 * @param {Object} obj
 * @returns {int}
 */
export function getObjectSize(obj) {
  let size = 0;

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      size++;
    }
  }

  return size;
}

/**
 * Get node by given key recursively
 *
 * @param {Object} node
 * @param {String} nodeKey
 */
export function getNodeByKey(node, nodeKey) {
  let foundNode = false;

  if (node.Xnodekey === nodeKey) {
    foundNode = node;
    return node;
  }

  if (!getObjectSize(node.GuiNodeCh)) {
    return false;
  }

  forEach(node.GuiNodeCh, child => {
    if (!foundNode) {
      foundNode = getNodeByKey(child, nodeKey);
    } else {
      return false;
    }
  });

  return foundNode;
}

function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

/**
 * Source: https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
 */
export function guid() {
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

/**
 * Transform data for usage in a Element UI tree component
 * @param {Array} data
 */
export function transformForTree(data) {
  let result = data.slice();

  result.forEach(item => {
    const itemId = parseInt(get(item, 'Id', ''));
    item.Id = itemId;
    const DescriptionLanq = get(item, 'DescriptionLanq', '');
    item.DescriptionLanq = `${DescriptionLanq}`;

    item.children = data.filter(childItem => {
      const childIdParent = parseInt(get(childItem, 'IdParent'));
      childItem.IdParent = childIdParent;
      return itemId === childIdParent;
    });
  });

  // Filter by root items
  const root = result.filter(item => {
    const itemIdParent = parseInt(get(item, 'IdParent'));
    return itemIdParent === 0;
  });

  return root;
}

/**
 * Transform data for usage in select
 * @param {Array} items
 * @param {Object} params
 */
export function transformForSelect(items, params = { label: 'displayName', value: 'id' }) {
  items.forEach(item => {
    item.label = item[params.label];
    item.value = item[params.value];
  });

  return items;
}
